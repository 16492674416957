@use 'sass:math';

// Option list component

// <ul class="switch">
// 	<p class="switch__number">05</p>
// 	<h2 class="switch__title">Would you like a cheeseburger?</h2>
// 	<li class="switch__item selected">
// 		Yes
// 	</li>
// 	<li class="switch__item">
// 		No
// 	</li>
// </ul>

// $spacer: $spacing/6;  Deprecated
$spacer: math.div($spacing, 6);

.switch {
	margin-top: $spacing*1.5;
	position: relative;

	li {
		display: inline-block;
		margin-right: $spacer*3;

		&:last-of-type {
			margin-right: 0;
		}
	}
}

.switch__number {
	position: absolute;
	left: -100px;
	top: 0;
	margin: 0;
	padding: 0;
	font-size: 42px;
	font-family: $font-primary;
	font-weight: 200;
}

.switch__title {
	// @extend .option-list__title;
	margin-left: 0;
	margin-right: 0;
}

.switch__item {
	font-weight: 700;
	color: $color-grey-dark;
	margin-top: -4px;

	&::before {
		content: "";
		display: inline-block;
		background: transparent;
		border: 1px solid tint($color-grey-primary, 50%);
		// width: $spacing/3; Deprecated
		// height: $spacing/3; Deprecated
		width: math.div($spacing, 3);
		height: math.div($spacing, 3);
		position: relative;
		top: 4px;
		margin-right: $spacer*2 - 4px;
		transition: all $transition;
	}

	&.selected {
		&::before {
			border: 3px solid $color-primary;
			background: tint($color-primary, 50%);
		}
	}
}