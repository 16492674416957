// ✓ refactored

.app-actions {
    width: 100%;

    .help-link {
        float: left;
    }

    .reset-link {
        float: right;
    }
}