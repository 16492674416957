// Text alignment

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

// Floats

.float-left {
	float: left;
}

.float-center {
	display: block;
	float: none;
	margin-left: auto;
	margin-right: auto;
}

.float-right {
	float: right;
}

// Center

.center {
	margin: 0 auto;
}

// Don't allow long words to break

.break,
%break {
	overflow-wrap: break-word;
	word-wrap: break-word;
}

// Hide visually and from screen readers

.hidden {
    display: none !important;
}

// Hide only visually, but have it available for screen readers

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

// Hide visually and from screen readers, but maintain layout

.invisible {
    visibility: hidden;
}

// Clearfix

%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }

}