// ✓ refactored

.page-content {
  background: tint($color-grey-primary, 80%);
  overflow-x: hidden;
  padding-top: 64px; // offset for .mobile-header
  padding-bottom: 64px; // offset for .mobile-drawer
  min-height: 100vh;

	@media only screen and ( min-width: $bp-large ) {
    background: tint($color-grey-primary, 85%);
		padding-top: 0;
		padding-bottom: 80px; // offset for .page-footer
    margin-left: 320px; // offset for .sidebar
    min-height: 100vh;
	}

  @media only screen and (min-width: $bp-xlarge) {
    margin-left: 375px; // offset for .sidebar
  }

  @media only screen and (min-width: $bp-large) and (max-height: $bp-shallow) {
    padding-bottom: 60px; // offset for .page-footer    
  }

  &__header {
    padding: 24px;
    min-height: 80px;
    background: tint($color-grey-primary, 75%);
    text-align: center;

    @supports (display: flex) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    
    h3 {
      color: shade($color-grey-primary, 10%);
      font-size: 18px;
      line-height: 1;
      font-weight: 700;
      letter-spacing: .1em;
      text-transform: uppercase;
      margin: 0;
    }
  }

  &__section {
    margin: 0;
    padding: 0 24px;

    @media only screen and ( min-width: $bp-xsmall ) {
      padding: 0 48px;
    }

    @media only screen and ( min-width: $bp-small ) {
      padding: 0 64px;
    }

    @media only screen and ( min-width: $bp-medium ) {
      padding: 0 80px;
    }
    
    a {
      &:not(.button) {
        @extend %link;
      }
    }
  }
}
