// ✓ refactored

.mobile-drawer {
    display: block;
    visibility: visible;
    width: 100%;
    position: fixed;
    top: calc(100% - 64px);
    height: 100%;
    height: calc(100% - 64px);
    z-index: 200;
    color: $color-white;
    overflow: auto;

    @media only screen and ( min-width: $bp-large ) {
		display: none;
		visibility: hidden;
	}

    &__trigger {
        height: 64px;
        width: 100%;
        border: none;
        font-family: $font-primary;
        font-weight: 500;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: .05em;
        color: $color-white;
        text-align: left;
        padding: 0 24px;
        background: shade($color-grey-primary, 10%); /* Old browsers */
        background: -moz-linear-gradient(top,  #a8adb4 0%, #939598 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #a8adb4 0%,#939598 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #a8adb4 0%,#939598 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        z-index: 300;
        position: absolute;
        top: 0;

        &::after {
            content: "";
            display: block;
            width: 16px;
            height: 10px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px;
            float: right;
            position: relative;
            top: 5px;
            background-image: url('../graphics/icons/icon-arrow-up.svg');
        }
    }

    &__wrapper {
        width: 100%;
        height: 100%;
        min-height: calc(100% - 128px);
        padding: 128px 0 64px;
        overflow: auto;
        background: #123fb3; /* Old browsers */
        background: -moz-linear-gradient(top,  #123fb3 0%, #12307e 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #123fb3 0%,#12307e 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #123fb3 0%,#12307e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        opacity: 0;
    }

    &__instruction {
        padding: 0 36px;
        margin-bottom: 24px;

        .lead {			
            font-weight: 700;
            font-size: 18px;
            line-height: 1;
            font-weight: 700;
            color: $color-white;
            margin-bottom: 48px;
		}
    }

    .timeline {
        @extend .timeline--white;
        margin-left: 30px;
    }

    .run-container {
        @extend .run-container--white;

        &:last-of-type {
            margin-bottom: 48px;
        }
    }

    &.open {
        top: 64px; // offset for .mobile-header

        .mobile-drawer__trigger {
            position: fixed;
            top:  64px;
            
            &::after {
                background-image: url('../graphics/icons/icon-arrow-down.svg');
            }
        }
    }
}

.mobile-drawer-open {
    overflow: hidden;

    .mobile-drawer {
        &__wrapper {
            opacity: 1;
        }

        &__content {
            -webkit-animation-delay: .1s;
            animation-delay: .1s;
            -webkit-animation-duration: .5s;
            animation-duration: .5s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp;
        }
    }
}

.ie11 {
    .mobile-drawer {
        height: calc(100% - 64px);

        .timeline {
            .timeline__items {
                margin-bottom: 32px;
            }
        }

        .run-container:last-of-type {
            margin-bottom: 64px;
        }
    }
}