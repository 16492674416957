// ✓ refactored

button {
	appearance: none;
	background: none;
	margin: 0;
	padding: 0;

	&:hover {
		cursor: pointer;
	}

	&:focus,
	&:active {
		outline: none;
	}

	&[disabled] {
		cursor: not-allowed;
	}
}

.button {
	background: $color-grey-primary;
	font-family: $font-primary;
	font-weight: 900;
	font-size: 13px;
	line-height: 1;
	letter-spacing: .1em;
	text-transform: uppercase;
	padding: 17px 36px;
	min-height: 48px;
	border: none;
	border-radius: 24px;
	text-align: center;
	color: $color-white;
	transition: opacity $transition, background $transition, box-shadow $transition;

	@media only screen and ( min-width: $bp-small ) {
		font-size: 14px;
		border-radius: 24px;
	}

	@media only screen and ( min-width: $bp-xlarge ) {
		padding: 20px 36px;
		min-height: 56px;
		font-size: 15px;
		border-radius: 32px;
	}

	&:hover,
	&:focus {
		color: $color-white;
		border: none;
		box-shadow: 0 3px 6px 0 rgba(61,66,77,0.10);
	}

	&:active {
		box-shadow: 0 1px 3px 0 rgba(61,66,77,0.20);
	}

	&[disabled] {
		background: tint($color-grey-secondary, 60%);
		color: $color-white;

		&:hover,
		&:focus,
		&:active {
			box-shadow: none;
			background: tint($color-grey-secondary, 60%);
		}
	}

	& + .button {
		margin-left: 36px;
	}

	&--primary {
		background: $color-primary;

		&:hover,
		&:focus {
			background: shade($color-primary, 10%);
		}

		&:active {
			background: shade($color-primary, 20%);
		}
	}

	&--secondary {
		background: #2F50A5;

		&:hover,
		&:focus {
			background: shade(#2F50A5, 15%);
		}

		&:active {
			background: shade(#2F50A5, 25%);
		}
	}

	&--tertiary {
		background: #33A1F0;

		&:hover,
		&:focus {
			background: shade(#33A1F0, 10%);
		}

		&:active {
			background: shade(#33A1F0, 20%);
		}
	}

	&--danger {
		background: $color-failure;

		&:hover,
		&:focus {
			background: shade($color-failure, 10%);
		}

		&:active {
			background: shade($color-failure, 20%);
		}
	}

	&--bordered {
		display: inline-block;
    	min-height: 28px;
    	vertical-align: middle;
		background: transparent;
		color: $color-grey-secondary;
		border: 1px solid $color-grey-primary;
		font-weight: 700;
		font-size: 10px;
		padding: 7px 15px 7px 16px;
		border-radius: 18px;

		&:hover,
		&:focus {
			background: transparent !important;
			border: 1px solid shade($color-grey-primary, 10%);
			color: shade($color-grey-secondary, 10%);
			box-shadow: 0 3px 6px 0 rgba(61,66,77,0.05) !important;
		}

		&:active {
			background: transparent !important;
			border: 1px solid shade($color-grey-primary, 20%);
			color: shade($color-grey-secondary, 20%);
			box-shadow: 0 1px 3px 0 rgba(61,66,77,0.10) !important;
		}
	}

	&--next-page {	
		&:after {
			content: "\00A0\276F";
		}
	}
}

.text-button {
	font-family: $font-primary;
	font-size: 12px;
	line-height: 1;
	font-weight: 700;
	margin: 0;
	color: $color-grey-secondary;
	border: none;
	letter-spacing: .0125em;
	text-transform: uppercase;

	&:hover,
	&:focus {
		color: shade($color-grey-secondary, 10%);
	}

	&:active {
		color: shade($color-grey-secondary, 20%);
	}
}