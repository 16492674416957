// ✓ refactored

.ReactModal {
    &__Overlay {
        background-color: rgba($color-black, .7) !important;
        opacity: 1 !important;
    }

    &__Content {
        background-color: transparent !important;
        min-width: 0 !important;
        max-width: 100%;
        padding: 16px !important;
        
        @media only screen and ( min-width: $bp-xsmall ) {
            padding: 32px !important;
            max-width: 500px + 64px;
        }

        @media only screen and ( min-width: $bp-small ) {
            max-width: 600px + 64px;
        }

        @media only screen and ( min-width: $bp-medium ) {
            max-width: 720px + 96px;
            padding: 48px !important;
        }

        &__Title,
        &__Body {
            text-align: center;
        }
        
        &__Title {
            padding: 32px 48px;
            background: tint($color-grey-primary, 85%);

            h3 {
                margin: 0;
            }
        }

        &__Body {
            padding: 48px 24px;
            background: $color-white;

            @media only screen and ( min-width: $bp-xsmall ) {
                padding: 48px;
            }

            @media only screen and ( min-width: $bp-medium ) {
                padding: 48px 96px;
            }

            p.large-emphasis {
                margin-top: 48px;
                margin-bottom: 48px;
            }
        }

        .button-group {
            position: static;
        }
    }
}