// ✓ refactored

.carousel {
  width: 100%;

  @media only screen and ( min-width: $bp-medium ) {
    width: calc(100% + 24px); // offset for slide padding
  }

  @media only screen and ( min-width: $bp-xlarge ) {
    width: calc(100% + 32px); // offset for slide padding
  }

  &__buttons {
    clear: left;

    @media only screen and ( min-width: $bp-medium ) {
      clear: none;
      position: absolute;
      top: -8px;
      right: 0;
    }

    button {
      margin: 16px 8px 0 0;
      position: relative;
      left: -13px;
      top: 0;

      @media only screen and ( min-width: $bp-medium ) {
        margin: 0 0 0 16px;
        position: static;
      }
    }
  }

  &__back-button,
  &__next-button {
    width: 36px;
    height: 36px;
    border: none;
    border-radius: none;
    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 0;
    transition: all $transition;

    @media only screen and ( min-width: $bp-medium ) {
      width: 48px;
      height: 48px;
      border-radius: 24px;
      border: 1px solid $color-grey-primary;
    }

    &[disabled] {
      opacity: 0.4;
    }
  }

  &__back-button {
    background-image: url('../graphics/icons/icon-arrow-left--grey.svg');
    background-position: center;

    @media only screen and ( min-width: $bp-medium ) {
      background-position: 17px center;
    }
  }

  &__next-button {
    background-image: url('../graphics/icons/icon-arrow-right--grey.svg');
    background-position: center;

    @media only screen and ( min-width: $bp-medium ) {
      background-position: 20px center;
    }
  }

  &__slider {
    overflow: visible !important;
  }

  &,
  &__slider,
  &__slider-tray-wrapper,
  &__slider-tray,
  &__slide {
    @extend %clearfix;
  }

  &__slider-tray {
    display: flex;

    li {
      padding-bottom: 0 !important;
      float: none !important;
      height: auto !important;
    }
  }

  &__inner-slide {
    position: static;
  }

  &__slide {
    @media only screen and ( max-width: 639px ) {
      opacity: .5;
      transition: opacity $transition;

      &:nth-of-type(1) {
        &.carousel__slide--visible {
          opacity: 1;
        }
      }

      &--hidden {
        opacity: .5;

        & + .carousel__slide--visible {
          opacity: 1;
        }
      }
    }

    &-focus-ring {
      outline: none !important;
    }
  }

  &__inner-slide {
    padding-right: 16px;
    cursor: pointer;

    @media only screen and ( min-width: $bp-medium ) {
      padding-right: 24px;
    }

    @media only screen and ( min-width: $bp-xlarge ) {
      padding-right: 32px;
    }
  }

  & + .button-group {
    &:not:empty {
      margin-top: 36px;
    }
  }
}