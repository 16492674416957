@use 'sass:math';

// Summary component

// $spacer: $spacing/6; Deprecated
$spacer: math.div($spacing, 6);

.summary {
	@extend %clearfix;
	position: relative;
	margin: 0 auto;
	padding: $spacing 0;
	text-align: center;
	max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    border-top: 1px solid tint($color-grey-primary, 50%);

    @media only screen and ( min-width: $bp-xsmall ) {
		
	}

	@media only screen and ( min-width: $bp-small ) {
		max-width: none;
		text-align: left;
		margin-top: $spacing*1.5;
		padding: 0;
		width: auto;
		border: none;
	}

	@media only screen and ( min-width: $bp-medium ) {
		margin-top: $spacing*2;
	}

	@media only screen and ( max-width: $bp-small - 1px ) {
		max-width: none;
		// padding-left: $spacing/2; Deprecated
		// padding-right: $spacing/2; Deprecated
		padding-left: math.div($spacing, 2);
		padding-right: math.div($spacing, 2);

		p {
			max-width: none;
		}
	}

	@media only screen and ( max-width: 360px ) {
		padding-left: $spacer*2;
		padding-right: $spacer*2;
	}
}

.summary__image {
	border: 1px solid tint($color-grey-primary, 50%);
	transition: all $transition;
	width: 100px;
	height: 100px;
	margin-bottom: $spacer*2;
	
	@media only screen and ( min-width: $bp-small ) {
		width: auto;
		height: auto;
		margin-bottom: 0;
	}
}

.summary__text {
	@media only screen and ( min-width: $bp-medium ) {
		padding-left: 20px;
	}

	h2 {
		@extend .h3;
		// margin: $spacing/6 0 $spacing/6; Deprecated
		margin: math.div($spacing, 6) 0 math.div($spacing, 6);

		@media only screen and ( max-width: $bp-small - 1px ) {
			font-size: 28px;
			line-height: 35px;
			border: 1px solid tint($color-grey-primary, 50%);
			border-bottom-width: 0;
			margin-bottom: 0;
			padding: 10px;

			&::after {
				display: none !important;
			}
		}

		@media only screen and ( max-width: $bp-xsmall - 1px ) {
			font-size: 21px;
			line-height: 25px;
		}
	}

	h4 {
		margin-bottom: 10px;
		color: tint($color-grey-dark, 20%);
	}

	p {
		margin-bottom: 0;
	}

	dl {
		@extend %clearfix;
		line-height: 24px;

		@media only screen and ( max-width: $bp-small - 1px ) {
			border: 1px solid tint($color-grey-primary, 50%);
			padding-top: 4px;
		}

		dt,
		dd {
			@media only screen and ( min-width: 320px ) {
				display: inline-block;
				float: left;
			}
		}

		dt {
			font-weight: 600;
			text-transform: uppercase;
			font-size: 12px;
			padding-top: 2px;
			letter-spacing: .02em;

			@media only screen and ( min-width: 320px ) {
				text-align: right;
				width: 45%;
				padding-right: 30px;
			}

			@media only screen and ( min-width: $bp-small ) {
				width: 25%;
				text-align: left;
			}

			@media only screen and (min-width: $bp-medium) {
				font-size: 14px;
			}

			&::after {
				content: "\003A";
			}
		}

		dd {
			margin-bottom: 5px;

			@media only screen and ( min-width: 320px ) {
				text-align: left;
				width: 55%;
			}

			@media only screen and ( min-width: $bp-small ) {
				width: 75%;
			}
		}
	}
}