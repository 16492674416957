/*------------------------------------*\
    $CONFIG
\*------------------------------------*/
// Global variables, mixins, config tools etc

@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
// @import 'font-face';
@import 'variables';
@import 'mixins';
@import 'keyframes';

/*------------------------------------*\
    $GENERIC
\*------------------------------------*/
// Ground-zero styles (normalize, resets, box-sizing, etc.)

@import 'normalize';
@import 'reset';
@import 'defaults';
@import 'keyframes';

/*------------------------------------*\
    $BASE
\*------------------------------------*/
// Unclassed HTML elements (type selectors)

@import 'typography';
@import 'forms';
@import 'images';
@import 'buttons';

/*------------------------------------*\
    $OBJECTS
\*------------------------------------*/
// Cosmetic-free design patterns (this is the first layer that will introduce classes)

@import 'grid';
@import 'constrain';
@import 'logo';

/*------------------------------------*\
    $COMPONENTS
\*------------------------------------*/
// Designed components, chunks of UI

@import 'sidebar';
@import 'app-title';
@import 'mobile-header';
@import 'mobile-menu-trigger';
@import 'mobile-menu-close';
@import 'mobile-menu';
@import 'page-footer';
@import 'mobile-drawer';
@import 'page-content';
@import 'field-set';
@import 'field';
@import 'field-step';
@import 'mobile-field-nav';
@import 'card';
@import 'carousel';
@import 'button-group';
@import 'timeline';
@import 'option-list';
@import 'summary-list';
@import 'switch';
@import 'app-actions';
@import 'run-container';
@import 'handy-tip';
@import 'next-actions';
@import 'input';
@import 'input-submit';
@import 'input-overlay';
@import 'input-overlay-close';
@import 'select';
@import 'boolean-icons';
@import 'summary';
@import 'form-notice';
@import 'splash';
@import 'component-table';
@import 'smallprint';
@import 'modal';

// Page specific components
@import 'summary-container';
@import 'send-form';

/*------------------------------------*\
    $TRUMPS
\*------------------------------------*/
// Helpers and overrides (usually use !important due to high specificity)

@import 'overrides';
@media print {
  @import 'print';
}
