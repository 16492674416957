// ✓ refactored

.mobile-menu-trigger {
    position: absolute;
    top: 0;
    right: 0;
    width: 64px;
    height: 64px;
    background: url('../graphics/icons/icon-menu-trigger.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    border: none;
    transition: opacity $transition;
}