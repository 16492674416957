// ✓ refactored

.form-notice {
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 64px 32px;
	background: $color-white;
	position: relative;
	margin: 64px 32px 0;

    @media only screen and ( min-width: $bp-xsmall ) {
		margin-top: 64px;
	}

	@media only screen and ( min-width: $bp-small ) {
		margin-top: 96px;
		margin-left: 0;
		margin-right: 0;
	}

	@media only screen and ( min-width: $bp-medium ) {
		margin-top: 128px;
	}

	p {
		margin: 0 0 24px;
	}

	.button {
		margin: 36px 0 0;
	}

	:last-child {
		margin-bottom: 0;
	}

	&--success {
		p.lead {
			color: $color-primary;
		}
	}

	&--failure {
		p.lead {
			color: $color-failure;
		}
	}
}