// ✓ refactored

.sidebar {
	display: none;
	visibility: hidden;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	width: 320px;
	height: calc(100% - 80px);
	background: $color-white;
	position: fixed;
	top: 0;
	z-index: 100;
	padding: 216px 0 0;
	overflow-x: hidden;
	overflow-y: auto;

	@media only screen and ( min-width: $bp-large ) {
		display: flex;
		visibility: visible;
	}

	@media only screen and ( min-width: $bp-xlarge ) {
		width: 375px;
	}

	@media only screen and ( max-height: $bp-shallow ) {
		height: calc(100% - 60px);
		padding-top: 200px;
	}

	&__main {
		width: 100%;
		margin: 0 0 auto;
		padding-top: 48px;

		> h2,
		.timeline__items {
			padding-left: 36px;

			@media only screen and ( min-width: $bp-xlarge ) {
				padding-left: 48px;
			}
		}

		h2 {
			font-weight: 900;
			font-size: 25px;
			line-height: 28px;
			padding-top: 17px;
			padding-bottom: 17px;
			margin: 0;
			color: $color-grey-dark;
			border-top: 1px solid rgba($color-grey-primary, .4);
			border-bottom: 1px solid rgba($color-grey-primary, .4);
			background: $color-white;
			position: fixed;
			top: 152px;
			width: 320px;
			z-index: 100;

			@media only screen and ( min-width: $bp-xlarge ) {
				margin-bottom: 64px;
				width: 375px;
			}

			@media only screen and ( max-height: $bp-shallow ) {
				margin-bottom: 48px;
				top: 136px;
			}
		}
	}

	&__instruction {
		padding: 0 36px;
		margin-bottom: 48px;

		.button-group {
			margin-top: 36px;
		}

		:last-child {
			margin-bottom: 0;
		}

		.lead {
			padding-right: 24px;

			@media only screen and ( min-width: $bp-large ) {
				font-size: 24px;
				line-height: 36px;
				letter-spacing: -0.01em;
			}

			@media only screen and ( min-width: $bp-xxlarge ) {
				font-size: 26px;
				line-height: 40px;
			}
		}

		@media only screen and ( min-width: $bp-xlarge ) {
			padding: 0 48px;
		}

		@media only screen and ( min-width: $bp-xlarge ) and ( min-height: 1080px ) {
			margin-bottom: 64px;
		}

		p:not(.lead),
		ul, ol {
			font-size: 16px;
			line-height: 24px;
			color: $color-grey-secondary;
		}

		ul, ol {
			li {
				margin: 0 0 24px;

				&:last-of-type {
					margin: 0;
				}
			}
		}

		ol {
			counter-reset: a;
			padding-top: 8px;
			
			li {
				counter-increment: a;
				padding-left: 52px;
				position: relative;
				
				&::before {
					content: counter(a);
					width: 36px;
					height: 36px;
					border-radius: 18px;
					background: tint($color-secondary, 94%);
					font-family: $font-primary;
					font-weight: 900;
					line-height: 36px;
					color: $color-grey-dark;
					text-align: center;
					position: absolute;
					left: 0;
					top: -1px;
				}
			}
		}
	}

	.run-container {
		background: $color-white;
	}

	.app-actions {
		@extend %clearfix;
		padding: 64px 36px;

		@media only screen and ( min-width: $bp-large ) {
			padding-top: 80px;
		}

		@media only screen and ( min-width: $bp-xlarge ) {
			padding-left: 48px;
			padding-right: 48px;
		}
	}
}

.ie11 {
	.sidebar {
		overflow-x: hidden;
		display: block;
		padding-bottom: 240px;
		min-height: calc(100% - 80px);

		&__main {
			min-height: calc(100vh - 452px);
			margin: 0;

			@media only screen and (max-height: $bp-shallow) {
				min-height: calc(100vh - 436px);
			}
		}

		> .timeline {
			display: flex;
			margin-bottom: auto;
		}

		> .timeline {
			margin-bottom: 64px;

			@media only screen and ( min-width: $bp-large ) {
				margin-bottom: 80px;
			}
		}
	}
}