// ✓ refactored

form {
  input, select,
  textarea {
    width: 100%;
    min-height: 48px;
    border: none;
    padding: 16px 24px;
  }

  input {
    &[type='checkbox'] {
      position: absolute;
      left: -9999px;
      width: 16px;
      height: 16px;

      & + label {
        display: inline-block;
        position: relative;
        top: 6px;
        left: -2px;
        width: 24px;
        height: 24px;
        margin: 0 14px 0 0;
        border-radius: 12px;
        border: 3px solid $color-grey-primary;
        white-space: nowrap;
        cursor: pointer;
        user-select: none;
        transition: background-color 0.2s, box-shadow 0.2s;

        &:hover,
        &:focus {
          box-shadow: 0 3px 6px 0 rgba(61, 66, 77, 0.1);
        }

        &:active {
          box-shadow: 0 1px 3px 0 rgba(61, 66, 77, 0.2);
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 4px;
          bottom: 4px;
          left: 4px;
          width: 10px;
          background-color: transparent;
          border-radius: 5px;
          transition: background-color 0.2s;
        }
      }

      &[agreed='true'] {
        & + label {
          border-color: tint($color-grey-primary, 20%);

          &::before {
            background-color: $color-success;
          }
        }
      }
    }
  }

  .required {
    color: rgba($color-failure, .7);
    font-weight: 700;
    display: none;
    visibility: hidden;

    &.active {
      display: inline;
      visibility: visible;
    }
  }

  .consent-alert {
    color: rgba($color-failure, .7);
    display: none;
    visibility: hidden;

    &.active {
      display: inline;
      visibility: visible;
    }
  }
}
