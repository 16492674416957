// Next action buttons

.next-actions {
	display: none;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

	.h5 {
		margin: 0;

		& + .p {
			margin-bottom: 20px;
		}
	}

	.button--mini {
		margin-top: 0;
		margin-bottom: $spacer;

		&.button--mini--primary {
			margin-top: $spacer;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&--mob {
		width: 100%;
		margin: 30px auto 0;
		padding: 0;

		.button--mini {
			margin-bottom: $spacer*1.5;

			&--primary {
				margin-top: $spacer*1.5 !important;
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.show-next-actions {
	.next-actions {
		@media only screen and ( min-width: $bp-small ) {
			display: flex !important;
			visibility: visible !important;
		}
	}

	& + .next-actions--mob {
		@media only screen and ( max-width: $bp-small - 1px ) {
			display: flex !important;
			visibility: visible !important;
		}
	}
}