// ✓ refactored

.splash {
  @extend %clearfix;
  position: relative;
  text-align: center;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100); /* --vh set in index.html */
  width: 100%;
  overflow: hidden;
  background: $color-white;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: $bp-large) {
    height: 100vh;
  }

  .logo {
    margin: 0 auto 24px;
    height: 32px;

    @media only screen and (min-width: $bp-medium) {
      margin-bottom: 48px;
      height: 40px;
    }

    @media only screen and (min-width: $bp-large) {
      margin-bottom: 64px;
    }

    @media only screen and (max-height: 800px) {
      margin-bottom: 32px;
    }
  }

  &__detail {
    width: 100%;
    background: $color-white;
    padding: 0 36px 24px;
    order: 2;

    @media only screen and ( min-width: 400px ) {
      padding-left: 48px;
      padding-right: 48px;
    }

    @media only screen and ( min-width: $bp-small ) {
      padding-bottom: 32px;
    }

    @media only screen and ( min-width: $bp-medium ) {
      width: 33.333%;
      margin: 0;
      padding: 48px 36px;
      right: auto;
      bottom: auto;
      height: 100vh;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }
    
    @media only screen and ( min-width: $bp-large ) {
			padding: 64px 48px;
		}

    @media only screen and ( min-width: $bp-xlarge ) {
      padding: 64px;
      align-items: center;
    }

    @media only screen and ( min-width: $bp-medium ) and ( max-width: $bp-large ) and (max-height: 640px) {
      display: block;
    }

    &__content {
      max-width: 500px;
      margin: auto auto 0 auto;
      padding-top: 12px;
      padding-bottom: 36px;
    
      @media only screen and (min-width: $bp-medium) {
        padding-top: 0;
        padding-bottom: 36px;
      }

      @media only screen and (max-height: 800px) {
        padding-bottom: 24px;
      }

      h1 {
        @media only screen and (max-width: $bp-medium - 1px) {
          br {
            display: none;
          }
        }
      }

      p {
        &:not(.lead) {
          @media only screen and (max-width: $bp-medium - 1px) {
            display: none;
          }
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .button-group {    
        margin-top: 36px;
    
        @media only screen and (min-width: $bp-medium) {
          margin-top: 48px;
        }

        @media only screen and (min-width: $bp-xlarge) {
          margin-top: 64px;
        }

        @media only screen and (max-height: 800px) {
          margin-top: 36px;
        }
      }
    }

    &__footer {
      margin-top: auto;
    }
  }

  &__graphic {
    width: 100%;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100); /* --vh set in index.html */
    background-image: url('../graphics/splash-bkg.jpg');
    background-repeat: no-repeat;
    background-position: 15% bottom;
    background-size: cover;

    @media only screen and (min-width: $bp-medium) {
      width: 66.666%;
      position: absolute;
      top: 0;
      right: 0;
      background-position: 15% center;
    }

    @media only screen and (min-width: $bp-large) {
      height: 100vh;
    }

    @media only screen and (max-width: $bp-medium - 1px) {
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 40vh; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 40); /* --vh set in index.html */
        position: absolute;
        bottom: 0;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+15,1+90 */
        background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 15%, rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 15%,rgba(255,255,255,1) 90%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 15%,rgba(255,255,255,1) 90%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
    }

    @media only screen and (max-width: 400px) {
      &::before {
        height: 30vh; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 30); /* --vh set in index.html */
      }
    }
  }

  a {
    &:not(.button) {
      @extend %link; 
    }
  }

  &--right {
    .splash__detail {
      @media only screen and ( min-width: $bp-medium ) {
        right: 0;
        left: auto;
        position: absolute;
      }
    }

    .splash__graphic {
      background-image: url('../graphics/confirmation-bkg.jpg');
      background-position: right center;

      @media only screen and ( min-width: $bp-medium ) {
        right: auto;
        left: 0;
      }
    }
  }
}

.ie11 {
  .splash {
    .logo {
      @media only screen and (min-width: $bp-medium) {
        margin-bottom: 32px;
      }
    }

    &__detail {
      &__footer {
        margin-bottom: 24px;
      }
    }
  } 
}