@use 'sass:math';

// Option list component

.option-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  margin: 0 0 -30px;
  padding: 0;
  position: relative;

  @media only screen and (min-width: $bp-small) {
    margin-bottom: 0;
  }

  @media only screen and (max-width: $bp-small - 1px) {
    justify-content: center;
  }

  @media only screen and (max-width: $bp-xsmall - 1px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 360px) {
    padding-left: $spacer * 2;
    padding-right: $spacer * 2;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  & + p {
  // margin-top: $spacing/2;  Deprecated
  margin-top: math.div($spacing, 2);
  }
}

.option-list__item {
  margin: 0 0 30px;
  position: relative;
  display: inline-block;
  width: 100%;
  flex-grow: 0;

  @media only screen and (max-width: $bp-xsmall - 1px) {
    max-width: 400px;
    margin-top: 30px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;

    &:nth-child(1) {
      margin-top: 0;
    }
  }

  @media only screen and (min-width: $bp-xsmall) {
    width: calc(50% - 20px);
  }

  @media only screen and (min-width: $bp-small) {
    width: 33.3333333333%;
    order: 1;
    margin: 0;

    &:nth-of-type(n + 4) {
      margin-top: 30px;
    }
  }

  > img {
    transition: background 0.8s ease;
    width: 100%;
  }

  .next-actions {
    @media only screen and (min-width: $bp-xsmall) {
    }

    @media only screen and (min-width: $bp-small) and (max-width: $bp-medium) {
      .button--mini {
        font-size: 12px;
      }
    }
  }

  .handy-tip {
    @media only screen and (max-width: $bp-small - 1px) {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;

      .h5 {
        display: none;
      }

      p:first-of-type {
        margin-top: 0;
      }
    }
  }

  &:hover,
  &:focus,
  &.hover,
  &.alert-active {
    .option-list__item__wrapper {
      border-color: $color-grey-primary;
      cursor: pointer;
    }

    .option-list__item__detail {
      border-color: $color-grey-primary;
    }

    .option-list__item__title {
      color: $color-grey-dark;
    }

    .icon {
      path,
      polygon {
        // fill: $color-grey-secondary;
      }
    }
  }

  &.selected {
    &.success,
    &.failure {
      .option-list__item__input__message {
        display: flex;

        &::before {
          content: '';
          display: inline-block;
          height: 30px;
          width: 30px;
          background-position: left center;
          background-repeat: no-repeat;
          background-size: 30px;
          vertical-align: middle;

          @media only screen and (min-width: $bp-medium) {
            height: 35px;
            width: 35px;
            background-size: 35px;
          }
        }
      }
    }

    &.success {
      .option-list__item__wrapper {
        border-color: $color-primary !important;
      }

      .option-list__item__input {
        color: $color-primary;
      }

      .option-list__item__input__message {
        color: $color-primary;
        padding-left: 30px;

        @media only screen and (min-width: $bp-medium) {
          padding-left: 35px;
        }

        &::before {
          // background-image: url('/assets/build/graphics/icon-success.svg');
        }
      }
    }

    &.failure {
      .option-list__item__input {
        color: $color-failure;
      }

      .option-list__item__input__message {
        color: $color-failure;

        &::before {
          // backgrounçd-image: url('/assets/build/graphics/icon-failure.svg');
        }
      }
    }
  }

  &.input-overlay {
    .option-list__item__overlay {
      // display: flex;

      // @media only screen and (max-width: $bp-xsmall - 1px) {
      //   background: tint($color-grey-primary, 80%);
      //   width: 100%;
      //   margin: 0;
      //   height: calc(100% - 100px); // offset for header and footer
      //   position: fixed;
      //   top: 50px;
      //   left: 0;
      //   z-index: 999999;
      //   max-width: none;
      // }

      // input {
      //   @media only screen and (max-width: $bp-xsmall - 1px) {
      //     background: tint($color-grey-primary, 80%);
      //   }
      // }
    }

    .option-list__item__wrapper {
      @media only screen and (max-width: $bp-xsmall - 1px) {
        margin: 0;
        border: none;
        cursor: default;
      }
    }

    &.failure {
      .option-list__item__wrapper {
        &::after {
          display: none;
        }
      }
    }
  }

  &.disabled,
  &.visually-disabled {
    .option-list__item__wrapper {
      opacity: 0.5;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.alert-active {
    &.disabled,
    &.visually-disabled {
      .option-list__item__wrapper {
        opacity: 1;
        border-color: tint($color-grey-primary, 50%);
      }

      .option-list__item__detail {
        opacity: 0.5;
      }
    }

    .option-list__alert {
      @media only screen and (min-width: $bp-xsmall) {
        display: flex;
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &--meta {
    &::after {
      display: none !important;
    }

    @media only screen and (max-width: $bp-xsmall - 1px) {
      margin-top: $spacer * 2;
    }

    @media only screen and (max-width: $bp-small - 1px) {
      width: 100% !important;
      height: auto;
    }

    @media only screen and (min-width: $bp-small) {
      order: 2;
    }

    &:hover,
    &:focus,
    &.hover {
      .option-list__item__wrapper {
        cursor: default;
        border-color: tint($color-grey-primary, 50%);
      }
    }

    .option-list__item__wrapper {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 15px;
      background: tint($color-grey-primary, 95%);

      @media only screen and (max-width: $bp-small - 1px) {
        display: block;
        border: none;
        background: none;
        padding: 0;
      }

      @media only screen and (min-width: $bp-medium) {
        padding: $spacer * 2;
      }

      > :last-child {
        margin-bottom: 0;
      }
    }
  }

  &--straight,
  &--dc-cable,
  &--dc-cable-corner {
    .option-list__item__overlay__field {
      &::after {
        display: block;
        content: 'mm';
        font-size: 16px;
        line-height: 16px;
        font-weight: 300;
        color: tint($color-grey-primary, 20%);
        line-height: 18px;
        text-transform: lowercase;
        position: absolute;
        top: 70px;
        right: 0;
        left: 0;
        margin: auto;

        @media only screen and (min-width: $bp-xsmall) and (max-width: 620px) {
          top: 55px;
        }

        @media only screen and (min-width: $bp-small) and (max-width: 900px) {
          top: 55px;
        }
      }
    }
  }

  &--straight,
  &--dc-cable,
  &--dc-cable-corner {
    .option-list__item__value {
      &::after {
        content: 'mm';
        display: block;
        font-size: 16px;
        line-height: 16px;
        font-weight: 600;
        color: $color-primary;
        line-height: 14px;
        text-transform: lowercase;
      }
    }
  }

  &--corner {
    .option-list__item__boolean {
      display: flex;
      flex-direction: row;
      font-size: 16px;
      line-height: 16px;
      font-weight: 300;
      color: tint($color-grey-primary, 20%);
      line-height: 18px;
      text-transform: lowercase;

      li {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 0 10px;
      }
    }
  }

  &__input {
    margin: 0 0 20px;
    color: $color-grey-dark;
    position: relative;
    transition: all 0.2s ease-out;
    height: 70px;
    font-size: 55px;
    line-height: 1;
    font-weight: 600;
    width: calc(100% - 60px);
    text-align: center;
    border: none;
    border-bottom: 1.5px solid $color-grey-primary;
    position: relative;
    border-radius: 0;
    padding: 0;
    max-width: 340px;

    @media only screen and (min-width: $bp-xsmall) {
      max-width: none;
    }

    @media only screen and (min-width: $bp-xsmall) and (max-width: 620px) {
      font-size: 45px;
      height: 55px;
    }

    @media only screen and (min-width: $bp-small) and (max-width: 900px) {
      font-size: 45px;
      height: 55px;
    }

    @media only screen and (min-width: $bp-medium) {
      width: calc(100% - 80px);
    }
  }

  &__input__message {
    display: none;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
    margin: 15px 20px 0;
    position: relative;
    padding-left: 40px;
    text-align: left;
    min-height: 30px;

    @media only screen and (max-width: $bp-xsmall - 1px) {
      margin-top: 30px;
      max-width: calc(340px - 80px);
      margin-left: 30px;
      margin-right: 30px;
    }

    @media only screen and (min-width: $bp-medium) {
      margin: 30px 30px 0;
      padding-left: 45px;
      min-height: 35px;
    }

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &__submit {
    display: none;
    z-index: 300;
    position: relative;
    margin: 30px auto 0;

    @media only screen and (min-width: $bp-xsmall) {
      margin-top: 15px;
    }

    @media only screen and (min-width: $bp-medium) {
      margin-top: $spacer * 3;
    }

    @media only screen and (max-height: 360px) and (max-width: $bp-xsmall - 1px) {
      // styles for input overlay when keyboard is active on mobile
      margin-top: 20px;
    }
  }

  &__value {
    display: none;
    visibility: hidden;
    opacity: 0;
    color: $color-primary;
    font-weight: 600;
    font-size: 55px;
    line-height: 60px;

    &.value-entered {
      @media only screen and (max-width: $bp-xsmall) {
        display: flex;
        align-items: baseline;
        visibility: visible;
        opacity: 1;
        position: absolute;
        top: -15px;
        right: 0;
        height: 30px;
        border: 1px solid $color-primary;
        padding: 3px 20px 4px 15px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        background: tint($color-grey-primary, 90%);

        &::after {
          display: flex;
          font-size: 12px;
        }

        .option-list__item__value__length {
          font-size: 18px;
          line-height: 21px;
          display: inline-block;
        }
      }

      @media only screen and (min-width: $bp-xsmall) {
        display: flex;
        visibility: visible;
        opacity: 1;
        justify-content: center;
        align-items: center;
        text-align: center;
        transition: all $transition;
        padding: 0 $spacer;
        border-left: 1px solid $color-primary;
        border-bottom: 1px solid $color-primary;
        position: absolute;
        top: 0;
        right: 0;
        text-align: right;
        font-size: 21px;
        line-height: 21px;
        font-weight: 600;
        background: rgba($color-white, 0.9);
        z-index: 200;
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        height: 60px;

        @media only screen and (min-width: $bp-medium) {
          padding: 0 $spacer * 2;
        }
      }
    }
  }
}

.option-list__item__wrapper {
  margin: 0 $spacer;
  border: 1px solid tint($color-grey-primary, 50%);
  height: 100%;

  @media only screen and (max-width: $bp-xsmall - 1px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
  }

  @media only screen and (min-width: $bp-xsmall) {
    transition: all $transition;
  }
}

.option-list__item__image {
  padding: 10px 10px 0px 10px;

  @media only screen and (max-width: $bp-xsmall - 1px) {
    width: 70px;
    height: 70px;
    flex-grow: 0;
    border-right: 1px solid tint($color-grey-primary, 50%);
  }

  @media only screen and (max-width: $bp-xsmall - 1px) and (min-width: 370px) {
    width: 80px;
    height: 80px;
  }

  @media only screen and (max-width: $bp-xsmall - 1px) and (min-width: 400px) {
    width: 100px;
    height: 100px;
  }
}

.option-list__item__detail {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  transition: all $transition;
  background: $color-white;
  padding: $spacer;

  @media only screen and (max-width: $bp-xsmall - 1px) {
    height: 70px;
  }

  @media only screen and (max-width: $bp-xsmall - 1px) and (min-width: 370px) {
    height: 80px;
  }

  @media only screen and (max-width: $bp-xsmall - 1px) and (min-width: 400px) {
    height: 100px;
  }

  @media only screen and (max-width: $bp-xsmall - 1px) {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 2px 0 2px $spacer;
  }

  @media only screen and (max-width: $bp-xsmall - 1px) and (min-width: 350px) {
    padding: 2px $spacer * 2;
  }

  &--part {
    @media only screen and (min-width: $bp-xsmall) {
      border-top: 1px solid tint($color-grey-primary, 50%);
    }
  }

  .icon {
    width: 30px;
    height: 30px;
    margin-top: 0;
    margin-right: 10px;
    flex-shrink: 0;

    @media only screen and (min-width: $bp-medium) {
      width: 40px;
      height: 40px;
    }

    @media only screen and (max-width: $bp-xsmall - 1px) {
      order: 2;
      margin: 0;
      position: absolute;
      top: -15px;
      right: -15px;
      background: $color-white;
    }

    path,
    polygon {
      // fill: $color-grey-primary;
      // transition: all $transition;
    }
  }

  .option-list__item__title {
    display: block;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: tint($color-grey-dark, 20%);
    transition: color $transition;

    @media only screen and (min-width: 360px) {
      font-size: 16px;
      line-height: 18px;
    }

    @media only screen and (min-width: 410px) {
      font-size: 18px;
      line-height: 24px;
    }

    @media only screen and (min-width: $bp-xsmall) {
      font-size: 15px;
      line-height: 18px;
    }

    @media only screen and (min-width: 600px) {
      font-size: 16px;
      line-height: 18px;
    }

    @media only screen and (min-width: 660px) {
      font-size: 18px;
      line-height: 24px;
    }

    @media only screen and (min-width: $bp-small) {
      font-size: 16px;
      line-height: 18px;
    }

    span {
      display: block;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      margin-top: 4px;

      @media only screen and (min-width: $bp-medium) {
        margin-top: 0;
      }

      @media only screen and (max-width: $bp-xsmall - 1px) {
        margin-bottom: 4px;
      }
    }
  }

  .option-list__item__description {
    color: $color-grey-secondary;

    span {
      display: inline;
      color: tint($color-grey-primary, 20%);
    }
  }
}

.option-list__item__overlay {
  width: 100%;
  height: 100%;
  background: $color-white;
  z-index: 100;
  position: absolute;
  text-align: center;
  display: none;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;

  &__label {
    max-width: 65%;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;

    @media only screen and (min-width: $bp-small) and (max-width: 850px) {
      font-size: 14px;
      line-height: 18px;
    }

    @media only screen and (min-width: $bp-xsmall) {
      margin-bottom: 15px;
    }

    @media only screen and (min-width: $bp-medium) {
      margin-bottom: $spacer * 3;
    }

    @media only screen and (max-height: 360px) and (max-width: $bp-xsmall - 1px) {
      // styles for input overlay when keyboard is active on mobile
      margin-bottom: 10px;
    }
  }

  &__field {
    position: relative;

    &.active {
      .option-list__item__submit {
        display: block;
      }
    }
  }
}

.option-list__alert {
  display: none;
  visibility: hidden;
  opacity: 0;
  font-size: 12px;
  color: $color-failure;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  width: calc(100% - 22px);
  height: calc(100% - 62px);
  margin: auto;
  text-align: center;
  padding: 0 $spacer * 2;
  background: rgba($color-white, 0.9);
  position: absolute;
  top: 1px;
  right: 0;
  left: 0;
  z-index: 200;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  transition: all $transition;
}

// IE11
html.no-supports {
  .option-list__item--meta {
    .option-list__item__wrapper {
      display: block;
      padding: 80px 20px 20px;

      .handy-tip {
        max-width: 100%;
      }
    }
  }

  .option-list__item__overlay__field {
    width: 100%;
  }

  .option-list__item__input__message {
    max-width: 80%;
  }
}
