// ✓ refactored

.card {
  background: $color-white;
  padding: 8px;
  position: relative;
  transition: all $transition;
  top: 0;
  left: 0;
  width: 100%;

  @media only screen and (min-width: $bp-medium) {
    padding: 12px;
  }

  @media only screen and (min-width: $bp-xlarge) {
    padding: 16px;
  }

  &__top {
    position: relative;
  }

  &__text {
    padding: 8px 0 0;

    @media only screen and (min-width: $bp-medium) {
      padding-top: 16px;
    }

    @media only screen and (min-width: $bp-xlarge) {
      padding-top: 16px;
    }
  }

  &__title {
    margin: 0 0 4px;
    font-weight: 700;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 20px;
    color: $color-grey-dark;

    @media only screen and (min-width: $bp-medium) {
      font-size: 20px;
      line-height: 24px;
    }

    @media only screen and (min-width: $bp-xlarge) {
      font-size: 21px;
      line-height: 24px;
      margin: 0 0 4px;
    }
  }

  &__description {
    margin: 0;
    font-size: 12px;
    line-height: 16px;

    @media only screen and (min-width: $bp-small) {
      font-size: 14px;
    }

    @media only screen and (min-width: $bp-medium) {
      font-size: 16px;
      line-height: 20px;
    }

    @media only screen and (min-width: $bp-xlarge) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__status {
    position: absolute;
    top: 0;
    right: 0;

    &__icon {
      display: block;
      width: 32px;
      height: 32px;
      float: left;

      @media only screen and (min-width: $bp-small) {
        width: 36px;
        height: 36px;
      }

      @media only screen and (min-width: $bp-medium) {
        width: 48px;
        height: 48px;
      }

      &--success {
        background-image: url('../graphics/icons/icon-tick.svg');
        background-color: $color-primary;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;

        @media only screen and (min-width: $bp-small) {
          background-size: 18px;
        }

        @media only screen and (min-width: $bp-medium) {
          background-size: 24px;
        }
      }
    }

    &__value {
      height: 32px;
      font-family: $font-primary;
      font-weight: 500;
      font-size: 16px;
      line-height: 1;
      color: $color-white;
      padding: 7px 12px 9px 44px;

      @media only screen and (min-width: $bp-small) {
        height: 36px;
        padding: 8px 16px 10px 52px;
        font-size: 18px;
      }

      @media only screen and (min-width: $bp-medium) {
        height: 48px;
        padding: 14px 24px 12px 72px;
        font-size: 21px;
      }

      &--success {
        background: #a5d552;
      }

      &::after {
        content: 'mm';
      }
    }
    &__quantity {
      height: 32px;
      font-family: $font-primary;
      font-weight: 500;
      font-size: 16px;
      line-height: 1;
      color: $color-white;
      padding: 7px 12px 9px 44px;

      @media only screen and (min-width: $bp-small) {
        height: 36px;
        padding: 8px 16px 10px 52px;
        font-size: 18px;
      }

      @media only screen and (min-width: $bp-medium) {
        height: 48px;
        padding: 14px 24px 12px 72px;
        font-size: 21px;
      }

      &--success {
        background: #a5d552;
      }

      &::before {
        content: 'x ';
      }
    }
  }

  &.deselected {
    @media only screen and (min-width: $bp-large) {
      opacity: 0.7;
    }
  }
}

.field.has-selection {
  .card {
    @media only screen and (min-width: $bp-medium) {
      margin-bottom: -8px; // offset height of all cards so that expanded card does not push page content below the carousel down
    }

    &.selected {
      -webkit-box-shadow: 0px 4px 12px 0px rgba($color-black, 0.08);
      -moz-box-shadow: 0px 4px 12px 0px rgba($color-black, 0.08);
      box-shadow: 0px 4px 12px 0px rgba($color-black, 0.08);

      @media only screen and (min-width: $bp-medium) {
        -webkit-box-shadow: 0px 8px 16px 0px rgba($color-black, 0.08);
        -moz-box-shadow: 0px 8px 16px 0px rgba($color-black, 0.08);
        box-shadow: 0px 8px 16px 0px rgba($color-black, 0.08);
        top: -8px;
        left: -4px;
        width: calc(100% + 8px);
      }
    }
  }
}
