// ✓ refactored

.mobile-field-nav {
    height: 48px;
    width: 100%;
    position: fixed;
    top: 64px;
    z-index: 100;
    -webkit-box-shadow: 0px 4px 16px 0px rgba($color-black,0.1);
    -moz-box-shadow: 0px 4px 16px 0px rgba($color-black,0.1);
    box-shadow: 0px 4px 16px 0px rgba($color-black,0.1);

    &__prev,
    &__next {
        display: block;
        background: $color-white;
        height: 100%;
        width: 50%;
        border: none;
        font-size: 13px;
        color: $color-grey-primary;
        text-align: left;
        padding: 13px 24px;

        &::after {
            content: "";
            display: block;
            width: 16px;
            height: 10px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px; 
            float: right;
            position: relative;
            top: 5.5px;
            opacity: .4;
        }

        &.disabled {
            background: tint($color-grey-primary, 96%);
            color: tint($color-grey-primary, 50%);
            text-shadow: 0px 1px 1px rgba($color-white, .8);

            &::after {
                opacity: .2;
                filter: drop-shadow(0px 1px 1px $color-white);
            }
        }
    }

    &__prev {
        float: left;
        border-right: 1px solid rgba($color-grey-primary, .2);

        &::after {
            background-image: url('../graphics/icons/icon-arrow-up--grey.svg');
        }
    }

    &__next {
        float: right;

        &::after {
            background-image: url('../graphics/icons/icon-arrow-down--grey.svg');
        }
    }
}