select {
	border-radius: 0;
    -webkit-appearance: none;
}

.select {
	&::-webkit-input-placeholder { color: $color-grey-primary; opacity: .6; }
	&::-moz-placeholder { color: $color-grey-primary; opacity: .6; } /* firefox 19+ */
	&:-ms-input-placeholder { color: $color-grey-primary; opacity: .6; } /* ie */
	&:-moz-placeholder { color: $color-grey-primary; opacity: .6; }
	&::placeholder { color: $color-grey-primary; opacity: .6; }

	label {
		display: inline-block;
		background: tint($color-secondary, 88%);
		font-family: $font-primary;
		color: $color-grey-dark;
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: .2em;
		height: 64px;
		padding: 21px 24px;

		& + input {
			display: block;
		}
	}

	&--label-inline {
		display: flex;
		flex-direction: row;

		select,
		label {
			display: inline-block;
		}

	}

	&__failure {
		display: block;
		margin-top: 24px;
		color: $color-failure;
	}

	&.failure {
		select {
			border: 1px solid tint($color-failure, 40%);
		}
	}

	& + .select-label {
		margin-top: 48px;

		@media only screen and ( min-width: $bp-medium ) {
			margin-top: 64px;
		}
	}

    & + .input-label {
		margin-top: 48px;

		@media only screen and ( min-width: $bp-medium ) {
			margin-top: 64px;
		}
	}

}

.select-label {
	font-size: 21px;
    line-height: 24px;
    font-weight: 500;
    font-family: $font-primary;
	color: $color-secondary;
	display: block;
	margin: 0 0 24px;

	& + small {
		display: block;
		margin: 0 0 24px;
	}
}