// ✓ refactored

// Limit width and center page content - use via @extend

%constrain {
	@media only screen and ( min-width: $bp-small ) {
		max-width: $constrain-width;
		margin-left: auto;
		margin-right: auto;
	}
}