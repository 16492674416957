// ✓ refactored

.button-group {
	@media only screen and (min-width: $bp-large) {
		position: relative;
		left: -2px;
	}

	.button {
		display: inline-block;
		
		& + .button {
			margin: 0 0 0 16px;

			@media only screen and (min-width: $bp-xlarge) {
				margin: 0 0 0 24px;
			}
		}
	}

	&__secondary-buttons {
		display: inline;

		.button {
			margin: 0 0 0 16px;

			@media only screen and (min-width: $bp-xlarge) {
				margin: 0 0 0 24px;
			}
		}

		&:only-child {
			.button {
				&:first-of-type {
					margin-left: 0;
				}
			}
		}

		@media only screen and (min-width: $bp-large) and (max-width: $bp-xlarge - 1px) {
			display: block;
			margin: 16px 0 0;

			.button:first-of-type {
				margin: 0;
			}
		}
	}

	&--vertical {
		align-items: center;
		display: inline-flex;
		flex-direction: column;

		.button {
			margin: 0 auto;
			width: 100%;

			& + .button {
				margin: 16px auto 0 auto;
			}
		}
	}
	
	&--centered {
		.button {
			margin: 0 auto;
		}
	}
    
    & + & {
        margin-top: 36px;
    }
}