// ✓ refactored

.run-container {
    &__run-number {
        text-transform: uppercase;
        height: 64px;
        margin: 0 0 ;
        padding: 0 48px 0 40px;
        font-family: $font-primary;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: .1em;
        color: $color-grey-dark;
        border: none;
        border-top: 1px solid rgba($color-grey-primary, .4);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-left: 8px solid #4967B3;
        background: tint($color-grey-primary, 95%);

        @media only screen and ( min-width: $bp-large ) {
            padding: 0 36px 0 28px;
        }

        @media only screen and ( min-width: $bp-xlarge ) {
            padding: 0 48px 0 40px;
        }

        &::after {
            content: "";
            display: inline-block;
            width: 16px;
            height: 9.88px;
            background-image: url('../graphics/icons/icon-arrow-down--dark-grey.svg');
            background-size: 16px;
            opacity: .3;
            position: relative;
            right: -1px;
            transform: rotate(-90deg);
            transition: all $transition;
        }
    }

    &__run-qty {
        color: $color-grey-primary;
        opacity: .6;
        margin-right: auto;
        font-weight: 400;

        &::before {
            display: inline;
            content: "\00D7";
            position: relative;
            top: 1px;
            margin-left: 16px;
        }
    }

    &__run-content {
        border-top: 1px solid rgba($color-grey-primary, .4);
        padding: 29.5px 0;
        transition: all $transition;
    }

    &:last-of-type {
        .run-container__run-number {
            border-bottom: 1px solid rgba($color-grey-primary, .4);
        }

        .run-container__run-content {
            padding-bottom: 0;
            border-top: none;
        }
    }

    &.closed {
        .run-container__run-number {
            color: $color-grey-dark;
            background: transparent;
            border-left-width: 0;
            padding-left: 48px;

            @media only screen and ( min-width: $bp-large ) {
                padding-left: 36px;
            }

            @media only screen and ( min-width: $bp-xlarge ) {
                padding-left: 48px;
            }

            &::after {
                transform: rotate(0);
                right: 0;
            }
        }
    }

    &--white {
        .run-container__run-number {
            color: $color-white !important;
            background: rgba($color-white, .1);
            border-left-color: rgba(#53B7E8, .6);
            

            &::after {
                right: 0;
                transform: rotate(180deg);
                background-image: url('../graphics/icons/icon-arrow-down.svg');
            }
        }

        &.closed {
            .run-container__run-number {
                background: transparent;
                border-left: none;

                &::after {
                    transform: rotate(0deg);
                }
            }
        }
    }
}