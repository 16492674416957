:root {
	--height75: calc(var(--vh, 1vh) * 75); /* --vh set in index.html */
	--height85: calc(var(--vh, 1vh) * 85);
	--height100: calc(var(--vh, 1vh) * 100);
}

html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

html,
body {
	-webkit-tap-highlight-color: transparent;
}

html {
	font-family: $font-secondary;
	font-weight: 400;
	font-style: normal;
	font-size: 100%;
	line-height: 1.6;
	-moz-font-feature-settings: "lnum" 1; 
	-ms-font-feature-settings: "lnum" 1;
	-o-font-feature-settings: "lnum" 1;
	-webkit-font-feature-settings: "lnum" 1;
	font-feature-settings: "lnum" 1;
	color: $color-grey-secondary;
	height: 100%;
}

body {
	background-color: $color-white;
	position: relative;
	overflow-x: hidden;

	&.input-overlay-open {
		@media only screen and ( max-width: $bp-xsmall - 1px ) {
			overflow: hidden;
		}
	}
}

*, *:before, *:after {
  box-sizing: inherit;
}

// Selection highlights

::-moz-selection { background: rgba($color-primary, .45); text-shadow:none; color:$color-white; }
::selection { background: rgba($color-primary, .45); text-shadow:none; color:$color-white; }

// Focus outline styles

:focus {
	outline: none;
}