// ✓ refactored

// Grid (https://cferdinandi.github.io/kraken/)

// Base grid styles: single column

.grid-container {
	@extend %constrain;
	max-width: 100%;
	width: 100%;

	@media only screen and ( min-width: $bp-small ) and ( max-width: $bp-medium - 1px ) {
		width: 100%;
		padding-left: 40px;
		padding-right: 40px;
	}

	@media only screen and (min-width: $bp-medium) {
		margin: 180px 0;
	}
}

.row {
	@media (min-width:$bp-small) {
		margin-left: $grid-margins * -1;
		margin-right: $grid-margins * -1;
	}
}

%grid-base {
	float: left;
	width: 100%;

	@media (min-width:$bp-small) {
		padding-left: $grid-margins;
		padding-right: $grid-margins;
	}
}

@each $grid, $width in ($grid-sizes) {
	.grid-#{$grid} {
		@extend %grid-base;
	}
}

// Reverses order of grid for content choreography

.grid-flip {
	float: right;
}

// Add columns to grid on bigger screens

@each $breakpoint, $prefix, $offset in ($grid-breakpoints){
	@media (min-width: $breakpoint) {
		@each $grid, $width in ($grid-sizes) {
			#{$prefix} .grid-#{$grid} {
				width: $width;
			}
		}
		@if $offset {
			@each $grid, $width in ($grid-sizes) {
				#{$prefix} .offset-#{$grid} {
					margin-left: $width;
				}
			}
		}
	}
}

// Add clearfix

.grid-container,
.row {
	@extend %clearfix;
}