// ✓ refactored

.field-step {
    color: $color-white;
    opacity: .3;
    font-family: $font-primary;
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    letter-spacing: .0125em;
    margin: 0;

    &__layout {
        font-size: 11px;
        margin-left: 4px;
        position: relative;
        top: -1px;
        text-transform: uppercase;
        letter-spacing: .1em;
    }
}