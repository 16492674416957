// ✓ refactored

.component-table {
  width: 100%;
  background: $color-white;
  margin-bottom: 48px;

  @media only screen and (min-width: $bp-large) {
    margin-bottom: 32px;
  }

  &__header,
  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;
    width: 100%;
  }

  &__header {
    min-height: 48px;
    background: tint($color-secondary, 90%);

    .component-table__cell {
      font-family: $font-primary;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
      color: $color-grey-dark;
      letter-spacing: 0.1em;

      @media only screen and (min-width: $bp-large) {
        font-size: 14px;
      }
    }
  }

  &__footer {
    .component-table__row {
      width: 100%;

      &:first-of-type {
        @media only screen and (min-width: $bp-small) {
          border-top: 32px solid tint($color-grey-primary, 85%);
          min-height: calc(48px + 32px);
        }
      }
    }
  }

  &__row {
    border-bottom: 1px solid rgba($color-grey-primary, 0.4);
    min-height: 48px;

    &:last-of-type {
      border-bottom: none;
    }

    .component-table__cell {
      &[data-table-header='Product'] {
        font-family: $font-primary;
        color: $color-grey-dark;
        font-weight: 700;
      }

      &[data-table-header='Price'] {
        font-family: $font-primary;
        color: $color-grey-secondary;
        font-weight: 400;

        @media only screen and (min-width: $bp-small) {
          font-size: 15px;
        }
      }
    }

    &--two {
      .component-table__cell {
        &:nth-of-type(1) {
          flex-basis: 50%;

          @media only screen and (min-width: $bp-small) {
            flex-basis: 80%;
          }
        }

        &:nth-of-type(2) {
          flex-basis: 50%;

          @media only screen and (min-width: $bp-small) {
            text-align: right;
            flex-basis: 20%;
          }
        }
      }
    }

    &--three {
      .component-table__cell {
        &:nth-of-type(1) {
          flex-basis: 50%;

          @media only screen and (min-width: $bp-small) {
            flex-basis: 70%;
          }
        }

        &:nth-of-type(2) {
          flex-basis: 25%;

          @media only screen and (min-width: $bp-small) {
            flex-basis: 15%;
          }
        }

        &:nth-of-type(3) {
          flex-basis: 25%;

          @media only screen and (min-width: $bp-small) {
            text-align: right;
            flex-basis: 15%;
          }
        }
      }
    }

    &--discountpercent {
      border-bottom: 1px solid $color-black;
      .component-table__cell {
        &:nth-of-type(1) {
          flex-basis: 50%;

          @media only screen and (min-width: $bp-small) {
            flex-basis: 80%;
          }
        }

        &:nth-of-type(2) {
          flex-basis: 50%;

          @media only screen and (min-width: $bp-small) {
            text-align: right;
            flex-basis: 20%;
          }
        }
      }
    }

    &--discountmsg {
      .component-table__cell {
        background: tint($color-primary, 0%);
        color: $color-white;
        font-size: 20px;
        line-height: 24px;

        &:nth-of-type(1) {
          flex-basis: 100%;
          font-family: $font-primary;
          font-weight: 700;
          text-align: center;
          padding: 24px;
          @media only screen and (min-width: $bp-small) {
            flex-basis: 100%;
          }
        }
      }
    }
  }

  &__cell {
    padding: 16px 24px 8px 0;
    flex-grow: 0;
    flex-basis: 20%;
    font-size: 14px;
    line-height: 16px;

    @media only screen and (min-width: $bp-medium) {
      font-size: 16px;
    }

    &:first-of-type {
      padding-left: 24px;
    }

    &:last-of-type {
      padding-right: 24px;
    }

    &[data-table-header='Product'] {
      flex-basis: 40%;

      span {
        margin-left: 16px;
      }
    }

    &[data-table-header='Length'],
    &[data-table-header='Price'] {
      flex-basis: 15%;

      @media only screen and (min-width: $bp-small) {
        text-align: right;

        input {
          text-align: right;
        }
      }
    }

    &[data-table-header='Percentage'] {
      .input-submit {
        &__input {
          padding-right: 24px;
        }
      }
    }

    &--input {
      padding: 0;
      text-align: right;
      font-weight: 700;

      .input-submit {
        width: 120px;
        margin-left: auto;

        &__input {
          width: calc(120px - 32px);
        }
      }
    }

    span {
      color: $color-grey-primary;
      font-family: $font-secondary;
      font-weight: 700;
      font-size: 12px;
    }
  }

  /* Responsive table styles */
  @media only screen and (max-width: $bp-small - 1px) {
    margin-top: 48px;

    /* Force table to not be like tables anymore */
    &,
    thead,
    tbody,
    tfoot,
    th,
    td,
    tr {
      display: block;
    }

    thead {
      /* Hide table headers */
      .component-table__header {
        position: absolute;
        top: -9999px;
        left: -9999px;
        font-size: 0;
      }
    }

    tbody,
    tfoot {
      .component-table__row {
        margin: 0;
        padding: 0;
        border: none;
        background: $color-white;
      }

      .component-table__cell {
        padding-top: 10px;
        padding-bottom: 8px;
        min-height: 36px;

        &[data-value='false'] {
          display: none !important;
        }

        /* Label the data */
        &[data-table-header='Product'] {
          background: tint($color-grey-primary, 75%);
          border: none;
          text-align: left;
        }

        &[data-table-header='Wattage'] {
          &::before {
            content: 'Wattage';
          }
        }

        &[data-table-header='Colour'] {
          &::before {
            content: 'Colour';
          }
        }

        &[data-table-header='IP Rating'] {
          &::before {
            content: 'IP Rating';
          }
        }

        &[data-table-header='Length'] {
          &::before {
            content: 'Length';
          }
        }

        &[data-table-header='Price'] {
          font-family: $font-secondary !important;

          &::before {
            content: 'Trade Price';
          }
        }

        &[data-table-header='Percentage'] {
          &::before {
            content: 'Percentage';
          }
        }

        &[data-table-header='Quantity'] {
          &::before {
            content: 'Quantity';
          }
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }

    .component-table__cell {
      /* Behave like a "row" */
      border: none;
      border-bottom: 1px solid rgba($color-grey-primary, 0.4);
      position: relative;
      padding-left: 50%;

      &[data-table-header='Product'] {
        text-align: center;
        padding-left: 16px;
        padding-right: 16px;
      }

      &[data-table-header='Product'] {
        &::before {
          top: 0;
        }
      }

      &::before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 10px;
        left: 16px;
        width: 45%;
        padding-right: 8px;
        white-space: nowrap;
      }
    }
  }
}

// IE11
.ie11 {
  .component-table {
    &__header,
    &__row {
      display: table;
    }

    &__cell {
      width: 120px;

      &[data-table-header='Product'] {
        width: 280px;
      }
    }
  }
}
