// ✓ refactored

.mobile-header {
    display: block;
    visibility: visible;
    background: $color-grey-dark; /* Old browsers */
    background: -moz-linear-gradient(top,  #504f52 0%, $color-grey-dark 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #504f52 0%,$color-grey-dark 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #504f52 0%,$color-grey-dark 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    height: 64px;
    padding: 19px 24px 17px;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;

    @media only screen and ( min-width: $bp-large ) {
		display: none;
		visibility: hidden;
	}

    h1 {
        font-size: 24px;
        line-height: 1;
        margin: 0;
        font-weight: 300;
        color: $color-white;
    }

    .field-step {
        position: absolute;
        top: 25px;
        left: 0;
        right: 0;
        text-align: center;
    }
}