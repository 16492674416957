// ✓ refactored

.page-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  padding: 0;
  width: 100%;
  height: 80px;
  overflow: hidden;
  background: $color-grey-dark;
  font-family: $font-primary;
  color: $color-grey-secondary;
  text-align: center;
  z-index: 100;

  @media only screen and (max-height: $bp-shallow) {
    height: 60px;
  }

  .prev-link,
  .next-link {
    white-space: nowrap;
    color: $color-grey-secondary;
    font-weight: 400;
    position: absolute;

    &,
    &:hover,
    &:focus,
    &:active {
      border: none;
      font-weight: 400;
    }

    &:hover,
    &:focus {
      color: $color-white;
    }
  }

  .prev-link {
    left: 48px;
    
    &::before {
      content: '\2190';
      display: inline-block;
      font-weight: 400;
      margin-right: 8px;
    }
  }

  .next-link {
    right: 48px;

    &::after {
      content: '\2192';
      display: inline-block;
      font-weight: 400;
      margin-left: 8px;
    }
  }

  ul.breadcrumb {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    font-size: 18px;

    @media only screen and (max-height: $bp-shallow) {
      font-size: 16px;
    }

    li {
      font-weight: 500;
      display: inherit;

      &::before {
        content: '\002F';
        display: inline-block;
        font-weight: 400;
        margin: 0 36px;

        @media only screen and (max-height: $bp-shallow) {
          margin: 0 32px;
        }
      }

      &:first-child {
        &::before {
          display: none;
        }
      }

      &.current {
        font-weight: 700;
        color: $color-white;

        @media only screen and (max-width: $bp-small - 1px) {
          display: block;
        }

        &::before {
          font-weight: 400;
          color: $color-grey-secondary;

          @media only screen and (max-width: $bp-small - 1px) {
            display: none;
          }
        }
      }

      span.step-number {
        display: none;

        @media only screen and (max-width: $bp-small - 1px) {
          display: inline;
          font-weight: 300;
          font-size: 12px;
          color: $color-grey-secondary;

          &::after {
            content: '\00A0\2F\00A0';
            margin-right: 2px;
          }
        }
      }
    }
  }
}
