#summary-container {
	.row:nth-child(n+2) {
		[class^="grid-"] {	
			@media only screen and ( max-width: $bp-small - 1px ) {
				border-top: 1px solid tint($color-grey-primary, 50%);
			}

			> {
				p:not(.micro-button) {
					@media only screen and ( max-width: $bp-small - 1px ) {
						text-align: center;
						margin-left: auto;
						margin-right: auto;
					}
				}
			}
		}
	}
}

