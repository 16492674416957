// ✓ refactored

.mobile-menu-close {
    position: absolute;
    top: 0;
    left: 0;
    width: 64px;
    height: 64px;
    background: url('../graphics/icons/icon-close.svg');
    background-repeat: no-repeat;
    background-position: 24px 24px;
    background-size: 19.09px;
    border: none;
}