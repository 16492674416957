// ✓ refactored

.app-title {
    width: 100%;
    text-align: center;
    background: $color-white;
    padding: 24px;
    text-align: right;
    z-index: 100;

    @media only screen and ( min-width: $bp-large ) {
        padding: 32px 0;
        text-align: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 320px;
    }

    @media only screen and (min-width: $bp-xlarge) {
        width: 375px;
    }

    @media only screen and ( min-width: $bp-large ) and ( max-height: $bp-shallow ) {
		padding-bottom: 24px;
	}

    .logo {
        margin: 0 0 24px auto;
        height: 32px;

        @media only screen and ( min-width: $bp-large ) {
            margin: 0 auto 32px;
            height: 40px;
        }

        @media only screen and ( min-width: $bp-large ) and ( max-height: $bp-shallow ) {
            margin: 0 auto 24px;
        }

        @media only screen and (max-height: 700px) {
            margin-bottom: 16px;
        }
    }
    
    h1 {
        text-transform: uppercase;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        padding: 0;
        color: rgba($color-grey-dark, .5);
        letter-spacing: .2em;
        margin: 0;

        @media only screen and ( min-width: $bp-xxsmall ) {
            font-size: 14px;
        }

        @media only screen and ( min-width: $bp-xsmall ) {
            font-size: 15px;
        }

        @media only screen and ( min-width: $bp-large ) {
            font-size: 13px;
            padding: 0;
            letter-spacing: .29em;
        }

        @media only screen and ( min-width: $bp-xlarge ) {
            font-size: 15px;
        }

        @media only screen and ( max-width: $bp-large - 1px ) {
            position: relative;
            left: 3px;
        }
    }		
}