// ✓ refactored

.smallprint {
    margin-top: 36px;
    max-width: 640px;
    
    p {
        line-height: 1;
    }

    small {
        font-size: 12px;

        &.warning {
            color: $color-warning;

            &::before {
                content: "Attention: ";
                font-weight: 700;
                font-family: $font-primary;
            }
        }
    }

    & + .button-group {
        margin-top: 36px;
    }
}