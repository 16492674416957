// ✓ refactored

.input-overlay-close {
    position: fixed;
    top: 8px;
    right: 8px;
    width: 64px;
    height: 64px;
    background: url('../graphics/icons/icon-close.svg');
    background-repeat: no-repeat;
    background-position: 24px 24px;
    background-size: 19.09px;
    border: none;
    z-index: 99999;
}