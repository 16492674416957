// ✓ refactored

.input-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: tint($color-grey-primary, 80%);
  height: 100vh;
  width: 100%;
  text-align: center;
  z-index: 400;
  overflow: hidden;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media only screen and (min-width: $bp-small) {
    padding-bottom: 25vh;
  }

  @media only screen and (min-width: $bp-large) {
    background: #f2f2f2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 100%;
    min-height: 0;
    padding-bottom: 0;
  }

  &__field {
    position: relative;
    margin: 24px auto;

    @media only screen and (min-width: $bp-large) {
      margin: 16px auto;
    }

    &::after {
      display: block;
      content: 'mm';
      font-size: 18px;
      line-height: 24px;
      font-weight: 300;
      color: tint($color-grey-primary, 20%);
      line-height: 1;
      text-transform: lowercase;
      margin: 8px auto 0;

      @media only screen and (min-width: $bp-large) {
        margin: 4px auto 0;
      }
    }
  }

  &__quantity-field {
    position: relative;
    margin: 24px auto;

    @media only screen and (min-width: $bp-large) {
      margin: 16px auto;
    }

    &::after {
      display: block;
      content: '';
      font-size: 18px;
      line-height: 24px;
      font-weight: 300;
      color: tint($color-grey-primary, 20%);
      line-height: 1;
      text-transform: lowercase;
      margin: 8px auto 0;

      @media only screen and (min-width: $bp-large) {
        margin: 4px auto 0;
      }
    }
  }

  &__label {
    font-size: 18px;
    line-height: 24px;
    margin: 0 auto;
    max-width: 180px;

    @media only screen and (min-width: $bp-large) {
      font-size: 16px;
    }

    @media only screen and (min-width: $bp-xlarge) {
      font-size: 18px;
    }
  }

  &__input {
    width: calc(100% - 96px);
    display: block;
    color: $color-grey-dark;
    transition: all $transition;
    height: 64px;
    font-size: 56px;
    line-height: 1;
    font-family: $font-primary;
    font-weight: 700;
    text-align: center;
    border: none;
    border-bottom: 1px solid $color-grey-primary;
    background: transparent;
    position: relative;
    margin: 0 auto;
    padding: 0;
    max-width: 360px;

    @media only screen and (min-width: $bp-large) {
      height: 48px;
      font-size: 36px;
    }

    @media only screen and (min-width: $bp-xlarge) {
      height: 64px;
      font-size: 48px;
    }
  }

  &__actions {
    display: none;
  }

  &__submit {
    display: inline-block;
  }

  &__message {
    display: block;
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    margin: 24px auto 0;
    max-width: 240px;

    @media only screen and (min-width: $bp-large) {
      margin: 16px auto 0;
    }

    @media only screen and (min-width: $bp-xlarge) {
      font-size: 13px;
    }

    &--failure {
      color: $color-failure;
    }
  }

  &.input-value-exists {
    .input-overlay__actions {
      display: block;
    }
  }

  &.input-failure {
    .input-overlay__field {
      @media only screen and (min-width: $bp-large) and (max-width: $bp-xlarge - 1px) {
        margin: 8px auto 16px;
      }
    }
  }
}

.input-overlay-active {
  @media only screen and (max-width: $bp-large - 1px) {
    overflow: hidden;

    .input-overlay.active {
      -webkit-overflow-scrolling: touch;
    }
  }

  .carousel {
    &__slider-tray {
      transition: none !important;
      transition-timing-function: unset !important;
      transform: none !important;
      will-change: auto !important;
    }

    &__slide {
      opacity: 1 !important;
    }
  }
}
