// ✓ refactored

input {
	border-radius: 0;
    -webkit-appearance: none;
}

.input {
	&::-webkit-input-placeholder { color: $color-grey-primary; opacity: .6; }
	&::-moz-placeholder { color: $color-grey-primary; opacity: .6; } /* firefox 19+ */
	&:-ms-input-placeholder { color: $color-grey-primary; opacity: .6; } /* ie */
	&:-moz-placeholder { color: $color-grey-primary; opacity: .6; }
	&::placeholder { color: $color-grey-primary; opacity: .6; }
	
	input,
	textarea {
		margin: 0 0 48px;
		border: none;
		background: $color-white;
		padding: 0 24px;
		color: $color-grey-dark;
		width: 100%;


		&[type="text"],
		&[type="email"],
		&[type="number"],
		textarea {
			height: 64px;
			font-size: 18px;
			line-height: 24px;
		}	
	}

	input[type="number"] {
		width: 180px;
		font-family: $font-primary;
		font-weight: 500;
		font-size: 24px;
	}

	label {
		display: inline-block;
		background: tint($color-secondary, 88%);
		font-family: $font-primary;
		color: $color-grey-dark;
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: .2em;
		height: 64px;
		padding: 21px 24px;

		& + input {
			display: block;
		}
	}

	&--label-inline {
		display: flex;
		flex-direction: row;

		input,
		label {
			display: inline-block;
		}

		input {
			flex-grow: 1;

			&[type="number"] {
				flex-grow: 1;
				width: 100%;
				max-width: 200px;
			}
		}

		button {
			border: none;
			
			&.input-submit__button {
				width: 64px;
				height: 64px;
				padding-left: 12px;
				padding-right: 12px;
				font-size: 14px;
			}
		}
	}

	&__failure {
		display: block;
		margin-top: 24px;
		color: $color-failure;
	}

	&.failure {
		input {
			border: 1px solid tint($color-failure, 40%);
		}
	}

	& + .input-label {
		margin-top: 48px;

		@media only screen and ( min-width: $bp-medium ) {
			margin-top: 64px;
		}
	}

	& + .select-label {
		margin-top: 48px;

		@media only screen and ( min-width: $bp-medium ) {
			margin-top: 64px;
		}
	}

}

.input-label {
	font-size: 21px;
    line-height: 24px;
    font-weight: 500;
    font-family: $font-primary;
	color: $color-secondary;
	display: block;
	margin: 0 0 24px;

	& + small {
		display: block;
		margin: 0 0 24px;
	}
}