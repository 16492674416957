// ✓ refactored

.mobile-menu {
  display: block;
  visibility: visible;
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  background: $color-grey-dark; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    $color-grey-dark 0%,
    #232324 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    $color-grey-dark 0%,
    #232324 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    $color-grey-dark 0%,
    #232324 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);

  @media only screen and (min-width: $bp-small) {
    left: auto;
    right: 0;
    width: 375px;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translate3d(375px, 0, 0);
    -ms-transform: translate3d(375px, 0, 0);
    transform: translate3d(375px, 0, 0);
  }

  @media only screen and ( min-width: $bp-large ) {
		display: none;
		visibility: hidden;
	}

  &__content {
    padding: 64px;

    @supports (display: flex) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: calc(100% - 120px);
    }

    @media only screen and (min-width: $bp-small) {
      padding: 96px;
    }

    @media only screen and (max-width: $bp-xxsmall) {
      padding: 64px 48px;
    }

    @media only screen and (max-height: 700px) {
      padding: 48px;
    }
  }

  .breadcrumb {
    width: calc(100% + 64px);
    border-top: 1px solid rgba($color-white, 0.2);

    @media only screen and (min-width: $bp-small) {
      width: calc(100% + 96px);
    }

    @media only screen and (max-width: $bp-xxsmall) {
      width: calc(100% + 48px);
    }

    &__item {
      display: block;
      width: 180px;
      max-width: calc(100% - 32px);
      position: relative;
      color: $color-white;
      transition: all $transition;

      &::after {
        content: '';
        display: block;
        height: 16px;
        width: 8.89px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-image: url('../graphics/icons/icon-arrow-right.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        border: none;
        opacity: 0.2;
        transition: all $transition;
      }

      &__status {
        display: block;
        margin: 8px 0 0;
        font-family: $font-secondary;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 1;
        letter-spacing: 0.15em;
        color: rgba($color-white, 0.4);

        &.incomplete {
          color: tint($color-failure, 20%);
        }

        &.complete {
          color: tint($color-success, 20%);
        }

        &:empty {
          margin: 0;
        }
      }
    }

    li {
      border-bottom: 1px solid rgba($color-white, 0.2);
      padding: 32px 0;
      font-family: $font-primary;
      font-weight: 700;
      font-size: 18px;
      line-height: 1;
      color: $color-white;

      .step-number {
        font-weight: 900;
        color: $color-grey-primary;
        transition: all $transition;

        &::after {
          content: '\00A0\002F\00A0';
          display: inline;
          font-weight: 300;
          transition: all $transition;
        }
      }

      &.current {
        .step-number {
          color: $color-primary;

          &::after {
            color: $color-grey-primary;
          }
        }

        .breadcrumb__item {
          &::after {
            background-image: url('../graphics/icons/icon-arrow-left.svg');
            opacity: 0.6;
          }
        }
      }
    }
  }

  .prev-link,
  .next-link {
    display: none;
  }

  .text-button {
    color: $color-white;
  }

  .app-actions {
    margin-top: 80px;

    @supports (display: flex) {
      margin-top: 64px;
    }
  }
}

.mobile-menu,
.page {
  transition: transform 0.25s cubic-bezier(0.16, 0.68, 0.43, 0.99);
}

.mobile-menu-open {
  overflow: hidden;

  .mobile-menu-trigger {
    opacity: 0;
    cursor: initial;
  }
  
  .mobile-menu,
  .page {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .page {
    -webkit-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);

    @media only screen and (min-width: $bp-small) {
      -webkit-transform: translate3d(-375px, 0, 0);
      -ms-transform: translate3d(-375px, 0, 0);
      transform: translate3d(-375px, 0, 0);
    }
  }
}
