// ✓ refactored

$inactive-opacity: .2;

.field {
  @extend %clearfix;
  margin: 0 auto;
  padding: 48px 0;
  position: relative;
  max-width: 800px;
  transition: opacity $transition;
  position: relative;
  min-height: calc(100vh - 128px);

  @supports (display: flex) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media only screen and (min-width: $bp-large) {
    padding: 120px 0;
    max-width: $constrain-width + 48px + 96px; // constrain width plus .field__nav width and space between
    min-height: calc(100vh - 80px);

    @supports (display: flex) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media only screen and (min-width: $bp-xxlarge) {
    max-width: $constrain-width + 48px + 128px; // constrain width plus .field__nav width and space between
  }

  @media only screen and (min-width: $bp-large) and (max-height: $bp-shallow) {
    min-height: calc(100vh - 60px);
  }

  &__content-wrapper {
    width: 100%;
    
    @media only screen and (min-width: $bp-large) {
      display: flex;
      position: relative;
    }

    &::before {
      @media only screen and (min-width: $bp-large) {
        content: '';
        display: block;
        min-height: calc(100vh - 80px - 240px); /* Fallback for browsers that do not support Custom Properties */
        height: 100%;
        position: absolute;
        top: 96px;
        left: 20px;
        width: 8px;
        background-color: tint($color-grey-primary, 85%);
        background-image: url('../graphics/dot.svg');
        background-repeat: space;
        margin: 36px 0;
        opacity: 0.3;
      }
    }
  }

  &__block {
    position: relative;
    width: calc(100% - 48px - 96px);
    float: right;
    opacity: $inactive-opacity;

    @supports (display: flex) {
      float: none;
      width: 100%;
    }

    @media only screen and (min-width: $bp-xxlarge) {
      width: calc(100% - 48px - 128px);

      @supports (display: flex) {
        width: 100%;
      }
    }
  }

  &__header {
    margin-bottom: 24px;

    @media only screen and (min-width: $bp-xsmall) {
      margin-bottom: 32px;
    }

    @media only screen and (min-width: 640px) {
      margin-bottom: 48px;
    }

    @media only screen and (min-width: $bp-large) {
      margin-bottom: 64px;
    }

    @media only screen and (max-width: $bp-large - 1px) {
      @supports (display: flex) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; 
      }
    }

    @media only screen and (max-width: $bp-small - 1px) {
      @supports (display: flex) {
        width: calc(100% + 48px);
      }
    }

    @media only screen and (max-width: $bp-xsmall - 1px) {
      @supports (display: flex) {
        width: calc(100% + 24px);
      }
    }
  }

  &__content {
    .carousel {
      @extend %clearfix;
    }
    
    .button-group {
      margin-top: 48px;

      @media only screen and (min-width: $bp-large) {
        margin-top: 64px;
      }

      .button,
      a.button {
        @media only screen and (max-width: 639px) {
          width: 100%;
          margin: 0 0 16px !important;
        }
      }

      &__secondary-buttons {
        @media only screen and (max-width: $bp-medium - 1px) {
          display: block;
          margin-top: 16px;

          .button,
          a.button {
            &:first-of-type {
              margin-left: 0;
            }
          }
        }

        @media only screen and (max-width: 639px) {
          display: block;
          margin-top: 8px;

          .button,
          a.button {
            width: calc(50% - 8px);
            margin-bottom: 16px !important;

            &:nth-child(1n) {
              margin-left: 0 !important;
              margin-right: 8px !important;
            }

            &:nth-child(2n) {
              margin-left: 8px !important;
              margin-right: 0 !important;
            }
          }
        }
      }
    }
  }

  &__title {
    display: inline-block;
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    font-family: $font-primary;
    color: $color-secondary;
    overflow: visible;
    margin: 0 0 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba($color-grey-primary, 0.4);
    position: relative;

    @media only screen and (min-width: $bp-medium) {
      font-size: 24px;
      line-height: 32px;
    }

    @media only screen and (min-width: $bp-large) {
      font-size: 26px;
      line-height: 32px;
    }

    @media only screen and (max-width: $bp-large - 1px) {
      @supports (display: flex) {
        display: flex;
        align-items: center;
        width: calc(100% - 48px);
        border: none;
        margin: 0;
        padding: 0 24px 24px 24px;
      }
    }

    @media only screen and (max-width: $bp-medium - 1px) {
      @supports (display: flex) {
        padding-left: 16px;
      }
    }

    &:only-child {
      margin-bottom: 0;
    }
  }

  &__status {
    span {
      font-weight: 500;
      font-family: $font-primary;

      @media only screen and (min-width: 640px) {
        font-size: 14px;
        line-height: 1;
        background: rgba($color-grey-primary, .7);
        color: $color-white;
        padding: 3px 8px 4px;
        margin-right: 6px;
      }
    }

    @media only screen and (min-width: 640px) {
      font-family: $font-primary;
      font-weight: 400;
      font-size: 12px;
      line-height: 1;
      position: absolute;
      bottom: -49px;
      right: 24px;
      margin: 0;
      vertical-align: middle;
      background: rgba($color-white, .7);
      color: shade($color-grey-primary, 10%);
      display: block;
      padding: 5px 8px 7px 0;
      height: 24px;

      &--success {
        span {
          background: rgba($color-success, .7);
        }
      }

      &--attention {
        span {
          background: rgba($color-attention, .7);
        }
      }

      &--failure {
        span {
          background: rgba($color-failure, .7);
        }
      }
    }

    @media only screen and (min-width: $bp-medium) {
      right: 0;
    }
  }

  &__run-number {
    font-weight: 700;

    &::after {
      content: '\00A0\002F\00A0';
      font-weight: 400;
      color: $color-grey-primary;
    }
  }

  &__instruction {
    margin: 0;
    color: shade($color-grey-primary, 20%);

    @media only screen and (min-width: $bp-small) {
      font-size: 14px;
      line-height: 20px;
    }

    @media only screen and (min-width: $bp-large) {
      font-size: 18px;
      line-height: 28px;
      max-width: 680px;
      margin-right: 268px;
    }

    @media only screen and (max-width: $bp-large - 1px) {
      padding-right: 40%;
    }

    @supports (display: flex) {
      @media only screen and (max-width: $bp-large - 1px) {
        width: 100%;
        padding: 24px 40% 0 0;
        border-top: 1px solid rgba($color-grey-primary, .4);
      }

      @media only screen and (max-width: $bp-small - 1px) {
        padding: 24px 274px 0 0;
      }

      @media only screen and (max-width: 640px) {
        padding: 24px 48px 0 0;
      }

      @media only screen and (max-width: $bp-xsmall - 1px) {
        padding: 24px 24px 0 0;
      }
    }

    & + .field__instruction {
      margin-top: 24px;
    }
  }

  &__number {
    background: #4967b3;
    color: $color-white;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    font-family: $font-primary;
    font-weight: 700;
    font-size: 24px;
    line-height: 48px;
    text-align: center;
    opacity: $inactive-opacity;

    @media only screen and (max-width: $bp-large - 1px) {
      @supports (display: flex) {
        margin-bottom: 24px;
      }
    }

    &--tick {
      background-image: url('../graphics/icons/icon-tick.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 22px;
    }
  }

  &__nav {
    position: relative;
    top: -95px;
    height: auto;
    width: 48px;
    float: left;
    padding-top: 24px;
    padding-bottom: 24px;

    @supports (display: flex) {
      float: none;
      height: auto;
      margin-right: 64px;

      @media only screen and (min-width: 1880px) {
        margin-right: 96px;
      }

      @media only screen and (min-width: $bp-large) {
        margin-bottom: auto;
        background-color: tint($color-grey-primary, 85%);
      }

      @media only screen and (min-width: $bp-xxlarge) {
        margin-right: 128px;
      }
    }

    &__prev,
    &__next {
      display: block;
      width: 24px;
      height: 14.82px;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      font-size: 0;
      transition: all $transition;
      opacity: 0;
      margin: 0 auto;
      border: none;
      position: relative;
      z-index: 10;
      border: none !important;
    }

    &__prev {
      background-image: url('../graphics/icons/icon-arrow-up--grey.svg');
      margin-bottom: 48px;
    }

    &__next {
      background-image: url('../graphics/icons/icon-arrow-down--grey.svg');
      margin-top: 48px;
    }
  }

  &__message {
    p {
        margin-bottom: 36px;
      
        &:last-child {
          margin-bottom: 48px;

          @media only screen and ( min-width: $bp-medium ) {
            margin-bottom: 64px;
          }
        }
    }
  }

  &__checkbox {
    padding-left: 40px;
    margin-bottom: 16px;
    position: relative;
    max-width: 960px;

    label {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
    }
  }

  &:last-of-type {
    .field__nav {
      &::after {
        display: none;
      }
    }

    .field__content-wrapper {
      &::before {
        display: none;
      }
    }
  }

  &.current {
    .field {
      &__nav {
        background-color: tint($color-grey-primary, 85%);
      }

      &__number,
      &__block {
        opacity: 1;
      }

      &__nav__prev,
      &__nav__next {
        opacity: 1;

        &[disabled] {
          opacity: 0.3;
        }
      }
    }
  }

  &--slim {
    align-items: normal;
    justify-content: flex-start;
    min-height: calc(75vh - 56px);
    
    @media only screen and (min-width: $bp-large) {
      min-height: calc(75vh - 40px);
    }

    @media only screen and (min-width: $bp-large) and (max-height: $bp-shallow) {
      min-height: calc(75vh - 30px);
    }
  }
}

.ie11 {
  .field {    
      @media only screen and (min-width: $bp-large) and (min-height: $bp-shallow) {
        padding: 180px 0;
      }

    &__content-wrapper {
      &::before {
        display: none;
      }
    }

    &__header {
      @media only screen and (max-width: $bp-large - 1px) {
        position: relative;
      }
    }

    &__number {
      @media only screen and (max-width: $bp-large - 1px) {
        position: absolute;
        left: 0;
      }
    }

    &__title {
      @media only screen and (max-width: $bp-large - 1px) {
        padding-left: 48px + 16px;
        padding-top: 12px;
      }
    }

    &__block {
      @media only screen and (max-width: $bp-large - 1px) {
        float: none;
        width: 100%;
      }
    }

    &__nav {
      float: none;
      height: auto;
      margin-right: 64px;

      @media only screen and (min-width: 1880px) {
        margin-right: 96px;
      }

      @media only screen and (min-width: $bp-large) {
        margin-bottom: auto;
        background-color: tint($color-grey-primary, 85%);
      }

      @media only screen and (min-width: $bp-xxlarge) {
        margin-right: 128px;
      }
    }

    &--slim {
      min-height: calc(85vh - 56px);
      
      @media only screen and (min-width: $bp-large) {
        min-height: calc(85vh - 40px);
      }
  
      @media only screen and (min-width: $bp-large) and (max-height: $bp-shallow) {
        min-height: calc(85vh - 30px);
      }
    }
  }
}