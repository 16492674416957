// ✓ refactored

.timeline {
	color: $color-grey-dark;

	&__items {
		width: 100%;
	}

	&__item {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		position: relative;

		&::after {
			content: "";
			display: block;
			height: calc(100% - 59px);
			width: 10px;
			background-image: url('../graphics/dot-small.svg');
			background-repeat: repeat-y;
			background-size: 10px;
			background-position: center top;
			margin: 48px auto 0;
			position: absolute;
			top: 0;
			left: 19px;
		}

		&__number {
			width: 48px;
			flex-basis: 48px;
			flex-shrink: 0;
			margin-right: 36px;
			font-size: 14px;
			text-align: center;
			position: relative;
		}

		&.disabled {
			&::after {
				opacity: .5;	
			}
			.timeline__item {
				&__number {
					opacity: .5;		
				}

				&__data {
					&__key,
					&__value {
						opacity: .5;
					}
				}
			}
		}

		&.current {
			&::after {
				opacity: .5;
			}

			.timeline__item {
				&__link {
					width: 48px;
					height: 48px;
					border-radius: 24px;
					font-size: 24px;
					line-height: 48px;
					top: -6px;
					background: tint(#4967b3, 90%);
				}

				&__data {
					&__key {
						color: tint($color-secondary, 40%);
					}

					&__value {
						color: tint($color-secondary, 20%);
					}
				}
			}
		}

		&:last-of-type {
			.timeline__item {
				&__number {
					&::after {
						display: none;
					}
				}

				&__data {
					margin-bottom: 0;
					padding-bottom: 0;
					border-bottom: 0;
				}
			}
		}

		&__link {
			display: block;
			width: 36px;
			height: 36px;
			border-radius: 18px;
			background: tint($color-secondary, 94%);
			border: none;
			font-family: $font-primary;
			font-weight: 900;
			line-height: 36px;
			color: $color-grey-dark;
			margin: 0 auto;
			position: relative;
			top: 0;
		}

		&__data {
			width: calc(100% - 48px);
			margin-bottom: 27px;
			padding-bottom: 27px;
			padding-right: 24px;
			border-bottom: 1px solid rgba($color-grey-primary, .3);
		
			&__key {
				display: block;
				font-family: $font-secondary;
				font-weight: 400;
				font-size: 12px;
				line-height: 1;
				text-transform: uppercase;
				letter-spacing: .1em;
				margin: 0 0 8px;
			}

			&__value {
				display: block;
				margin-bottom: 20px;				
				font-family: $font-primary;
				font-weight: 900;
				font-size: 16px;
				line-height: 1;
				margin: 0;
			}
		}
	}

	&--white {
        color: $color-white;

        .timeline__item {
            &::after {
                background-image: url('../graphics/dot-small--white.svg');
			}
			
			&.current {
				.timeline__item {
					&__link {
						background: rgba(#53B7E8, .6);
					}

					&__data {
						&__key {
							color: $color-white;
						}

						&__value {
							color: $color-white;
						}
					}
				}
			}
        }

        .timeline__item__link {
			color: $color-white;
            background: rgba($color-white, .1);

            &[disabled] {
                color: $color-white;
            }
		}
    }
}

a.timeline__item__link {
	&:hover,
	&:focus {
		color: $color-secondary;
		border: none;
		background: tint($color-secondary, 90%);
	}

	&[disabled] {
		opacity: .5;
		color: $color-grey-dark;
	}
}












