// ✓ refactored

p,
.para {
	color: $color-grey-secondary;
	font-size: 14px;
	line-height: 24px;
	margin: 0 0 16px;

	@media only screen and ( min-width: $bp-small ) {
		font-size: 15px;
		line-height: 24px;
	}

	@media only screen and ( min-width: $bp-medium ) {
		font-size: 14px;
		line-height: 24px;
	}

	@media only screen and ( min-width: $bp-large ) {
		font-size: 15px;
		line-height: 24px;
	}

	@media only screen and ( min-width: $bp-xlarge ) {
		font-size: 16px;
		line-height: 28px;
	}

	sup {
		font-size: 65%;
		top: .25em;
	}

	&.lead {
		font-family: $font-primary;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: $color-grey-primary;
		margin: 0 0 24px;

		@media only screen and ( min-width: $bp-large ) {
			font-size: 20px;
			line-height: 32px;
		}

		@media only screen and ( min-width: $bp-xlarge ) {
			font-size: 21px;
			line-height: 32px;
		}
	}

	&.large-emphasis {
		font-family: $font-primary;
		font-weight: 700;
		color: $color-grey-dark;
		font-size: 21px;
		line-height: 24px;
		letter-spacing: .0125em;
		text-transform: uppercase;
	}

	&.warning-message {
		color: $color-warning;
		font-weight: 600;
	}

	&.failure-message {
		color: $color-failure;
		font-weight: 600;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

// Links

a {
	text-decoration: none;
	border: none;
	transition: all $transition;
}

%link {
	color: tint($color-grey-dark, 10%);
	font-weight: 600;
	border-bottom: 1px solid transparent;

	&:hover,
	&:focus {
		color: $color-grey-dark;
		border-bottom: 1px solid $color-primary;
	}
}

// Lists

ul,
ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

ul ul,
ul ol,
ol ol,
ol ul {
	margin-bottom: 0;
}

dl,
dd {
	margin: 0;
	padding: 0;
}

dt {
	font-weight: bold;
}

// Headings

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
	color: $color-grey-dark;
	font-family: $font-primary;
	font-weight: 900;
	margin: 0 0 24px;

	@media only screen and ( min-width: $bp-xlarge ) {
		margin-bottom: 32px;
	}
}

h1, .h1 {
	font-size: 30px;
	line-height: 40px;

	@media only screen and ( min-width: 1280px ) {
		font-size: 34px;
		line-height: 48px;
	}

	@media only screen and ( min-width: $bp-large ) {
		font-size: 40px;
		line-height: 52px;
	}

	@media only screen and ( min-width: $bp-xlarge ) {
		font-size: 48px;
		line-height: 64px;
	}

	sup {
		font-size: 25%;
	}
}

h2, .h2 {
	font-size: 25px;
	line-height: 32px;

	@media only screen and ( min-width: $bp-small ) {
		font-size: 35px;
		line-height: 40px;
	}
	@media only screen and ( min-width: $bp-xlarge ) {
		font-size: 40px;
		line-height: 48px;
	}
}

h3, .h3 {
    font-size: 18px;
	line-height: 24px;
	font-weight: 700;

	@media only screen and ( min-width: $bp-xsmall ) {
	    font-size: 20px;
	    line-height: 32px;
	}
	@media only screen and ( min-width: $bp-small ) {
		font-size: 25px;
		line-height: 36px;
	}
	@media only screen and ( min-width: $bp-medium ) {
		font-size: 30px;
		line-height: 36px;
	}
}

// Lines, Quotes and Emphasis

hr {
	border: 0;
	border-top: 1px solid rgba($color-grey-primary, .4);
	box-sizing: content-box;
	margin: 120px auto;
	overflow: visible;
}

// Prevent the duplicate application of `bolder` by the next rule in Safari 6

b,
strong {
	font-weight: inherit;
}

// Add the correct font weight in Chrome, Edge, and Safari

b,
strong {
	font-weight: bolder;
}

// 1. Remove the bottom border in Firefox 39-

abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	text-decoration: underline dotted;
}

// Address styling not present in Safari and Chrome

dfn {
	font-style: italic;
}

// Address styling not present in IE 8/9

mark {
	background: #ff0;
	color: #000000;
}

// Address inconsistent and variable font size in all browsers

small {
	font-size: 70%;

	@media only screen and ( min-width: $bp-small ) {
		font-size: 80%;	
	}
}

// Prevent `sub` and `sup` affecting `line-height` in all browsers

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}