// Handy tip component

.handy-tip {
	display: block;

	.h5 {
		margin: 0;
	}

	p {
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&__text--mob {
	    display: inline;
	    visibility: visible;

	    @media only screen and ( min-width: $bp-small ) {
	        display: none;
	        visibility: hidden;
	    }
	}

	&__text--desk {
	    display: none;
	    visibility: hidden;

	    @media only screen and ( min-width: $bp-small ) {
	        display: inline;
	        visibility: visible;
	    }
	}
}

.show-next-actions {
	.handy-tip {
		@media only screen and ( min-width: $bp-small ) {
			display: none !important;
		}
	}
}