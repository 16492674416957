// ✓ refactored

.input-submit {
    display: flex;
    justify-content: space-between;
    position: relative;

    &__input,
    &__button {
        border: 1px solid rgba($color-grey-primary, 0.4);
    }

    &__input {
        border-right: none;
        padding: 0 12px;
        
        &:focus {
            & + .input-submit__button {
                background: tint($color-secondary, 64%);
                color: $color-white;

                &:hover,
                &:focus {
                    color: $color-white;
                    border: none;
                    background: tint($color-secondary, 56%);
                    box-shadow: none;
                }

                &:hover {
                    cursor: pointer;
                }

                & + .input-submit__span {
                    right: 40px;
                }
            }
        }
    }

    &__button {
        display: inline-block;
        width: 32px;
        height: 32px;
        background: transparent;
        color: transparent;
        border-left: none;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        font-family: $font-primary;
        letter-spacing: .1em;
        transition: background-color $transition;
        padding-left: 7px;
        padding-right: 7px;
        
        &:hover,
        &:focus,
        &:active {
            cursor: initial;
        }

        &:active {
            color: $color-white;
            background: tint($color-secondary, 42%);
            box-shadow: none;

            & + .input-submit__span {
                right: 40px;
            }
        }
    }

    &__span {
        position: absolute;
        background: transparent;
        top: 9px;
        right: 8px;
        bottom: 0;
        left: auto;
        margin: auto;
        font-size: 14px !important;
        color: $color-grey-primary !important;
    }
}