@use 'sass:math';

// FONTS

$font-primary: 			"Scene W01", "open-sans", "Helvetica Neue", Arial, sans-serif;
$font-secondary: 		"Open Sans", "Helvetica Neue", Arial, sans-serif;

// COLOURS

$color-primary: 		#76B900;
$color-secondary: 		#001D68;

$color-black: 			#000;
$color-white: 			#FFF;

$color-grey-primary: 	#B0B7BC;
$color-grey-secondary: 	#808285;
$color-grey-dark: 		#414042;

$color-info: 			#53B7E8; 		// Blue
$color-success: 		#9ACE41; 		// Green
$color-attention: 		#F2BD1F; 		// Yellow
$color-warning: 		#F27B35; 		// Orange
$color-failure: 		#D6474C; 		// Red

// BREAKPOINTS

$bp-xxsmall:			374px;
$bp-xsmall:				540px;
$bp-small:				768px;
$bp-medium:				1025px;
$bp-large:				1367px;
$bp-xlarge:				1680px;
$bp-xxlarge:			2100px;
$bp-shallow:			1000px; // Used for shallow height screens (e.g. laptops) via min-height media query

// DEFAULTS

$constrain-width:	 	1144px;
$spacing:				64px;
$spacer: 				8px;
$border-radius:			8px;
$opacity:				.85;
$transition:			.25s ease-out;

// GRID

// $grid-margins: $spacing/6;  Deprecated
$grid-margins: math.div($spacing, 6);
$grid-sizes: (
	// Grid width options
	// Add/remove grid's as needed
	// $name: $width
	// $name - {string} class suffix
	// $width - {string} width of the grid
	one: 	08.3333333333%,
	two:	16.6666666667%,
	three: 	25%,
	four: 	33.3333333333%,
	five:	41.6666666667%,
	six: 	50%,
	seven:	58.3333333333%,
	eight: 	66.6666666667%,
	nine:	75%,
	ten:	83.3333333333%,
	eleven:	91.6666666667%,
	twelve: 100%
);
$grid-breakpoints: (
	// Breakpoints at which to activate grid
	// Add/remove breakpoints as needed
	// ($breakpoint, $prefix-class, $include-offsets)
	// $breakpoint - {string|variable} the breakpoint
	// $prefix-class - {string|optional} class to be used with `.row` to activate grid
	// $include-offsets - {boolean} if true, include offset classes at this breakpoint
	($bp-xsmall, ".row-start-xsmall", false),
	($bp-small, ".row-start-small", false),
	($bp-medium, null, true),
);

